export const apiPermissions = {
  LCAT_BASE: "LCAT_BASE",
  LCAT_ESMLC: "LCAT_ESMLC",
  LCAT_ALLOWLIST_ALL_MSISDNS: "LCAT_ALLOWLIST_ALL_MSISDNS",
  LCAT_GMLC_GROUP_PASSWORDS: "LCAT_GMLC_GROUP_PASSWORDS",
  LCAT_REPORTDEFS_ALL: "LCAT_REPORTDEFS_ALL",
};

export const appPermissions = {
  global: {
    debug: "global:debug",
  },
  mtlrSetPage: {
    view: "mtlr-set-page:view",
  },
  reportPage: {
    view: "report-page:view",
    reportDefinitions: {
      editName: "report-page:report-definitions:edit-name",
      delete: "report-page:report-definitions:delete",
    },
  },
  manageDataPage: {
    view: "manage-data-page:view",
  },
};

type PermissionMapping = [string, string[]];
const mapping: PermissionMapping[] = [
  [
    apiPermissions.LCAT_BASE,
    [appPermissions.mtlrSetPage.view, appPermissions.reportPage.view, appPermissions.manageDataPage.view],
  ],
  [apiPermissions.LCAT_ESMLC, []],
  [apiPermissions.LCAT_ALLOWLIST_ALL_MSISDNS, []],
  [apiPermissions.LCAT_GMLC_GROUP_PASSWORDS, []],
  [
    apiPermissions.LCAT_REPORTDEFS_ALL,
    [appPermissions.reportPage.reportDefinitions.editName, appPermissions.reportPage.reportDefinitions.delete],
  ],
];

const permissionMap = new Map<string, string[]>(mapping);

/**
 * Convert an array of API permissions into the corresponding app permissions.
 *
 * @param apiPermissions An array of API permissions.
 * @returns An array of app permissions.
 */
export const apiToAppPermissions = (apiPermissions: string[]) => {
  return apiPermissions.reduce<string[]>(
    (appPermissions, apiPermission) => [...appPermissions, ...(permissionMap.get(apiPermission) || [])],
    []
  );
};
