import { useField } from "formik";
import { getLocalTimeAsUTC } from "../../../utilities/datetime";
import DateTimePicker from "../shared/DateTimePicker";
import { useUserContext } from "../../../contexts/UserContext";

type Props = {
  name: string;
  className?: string;
};

const StartDateTime = ({ name, className = "" }: Props) => {
  const { userTimeZoneIdentifier } = useUserContext();
  const [field, meta, helpers] = useField(name);

  return (
    <DateTimePicker
      {...field}
      onChange={(value: Date) => {
        helpers.setValue(value);
      }}
      isInvalid={meta.touched && meta.error !== undefined}
      minDate={getLocalTimeAsUTC(userTimeZoneIdentifier).toJSDate()}
      className={className}
    />
  );
};

export default StartDateTime;
