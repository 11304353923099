import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ButtonHTMLAttributes, MouseEvent } from "react";
import styled from "styled-components";
import { theme } from "../../../utilities/constants";

const StyledButton = styled.button`
  background: none;
  border: 0;
  padding: 0;

  &:hover:not([disabled]) {
    filter: brightness(85%);
  }

  /* the following classes match the names of the icons */
  &.pause {
    color: #0d6efd;
  }

  &.play {
    color: ${theme.colors.green};
  }

  &.circle-play {
    color: #dc3545;
  }

  &.power-off {
    color: #dc3545
  }

  &.file-lines {
    color: #0d6efd;
  }

  &:disabled {
    cursor: default;
    color: #adb5bd;
  }
`;

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  iconName: IconName;
  onClick: (e: MouseEvent<HTMLButtonElement>, props: any) => void;
  disabled?: boolean;
};

const ControlButton = ({ iconName, onClick, disabled = false }: Props) => {
  return (
    <StyledButton className={iconName} onClick={onClick} disabled={disabled}>
      <FontAwesomeIcon icon={iconName} />
    </StyledButton>
  );
};

export default ControlButton;
