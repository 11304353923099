import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Form, Formik } from "formik";
import { Button, FormControl, Modal } from "react-bootstrap";
import LoadReportDefinitionModal from "./LoadReportDefinitionModal/LoadReportDefinitionModal";
import { useEffect, useState } from "react";
import ReportDefinition from "../../../services/models/ReportDefinitions/ReportDefinition";
import { ObjectSchema, object, string } from "yup";
import { getReportDefinitionSummaries } from "../../../services/reportDefinitionService";
import ReportDefinitionSummary from "../../../services/models/ReportDefinitions/ReportDefinitionSummary";
import { SpringValues, animated, useTransition } from "react-spring";
import styled from "styled-components";
import classNames from "classnames";
import { useUserContext } from "../../../contexts/UserContext";
import { formatDateTimeUtc } from "../shared/formatters";
import ReportFormData from "./ReportFormData";
import { v4 } from "uuid";

type Props = {
  isDirty: boolean;
  initialReportName: string;
  onLoad: (reportDefinition: ReportDefinition, oldFormData?: ReportFormData) => void;
  onSave: (name: string) => Promise<string | void> | void;
  onUpdate: (summary: ReportDefinitionSummary) => Promise<string | void> | void;
};

type FormData = {
  reportName: string;
  rand: string;
};

// Chrome's user-agent is overriding the background color when the input is auto-filled, so it needs to be set here
// https://stackoverflow.com/a/63589498/6392642
const NameField = styled(FormControl)`
  width: 300px;

  &.reportNameInput,
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }

  input:-webkit-autofill,
  &.is-dirty {
    -webkit-box-shadow: 0 0 0px 1000px #fffaeb inset !important;
    font-style: italic;
  }
`;

const validationSchema: ObjectSchema<FormData> = object().shape({
  reportName: string().required(),
  rand: string().defined(),
});

const SaveLoadReportForm = ({ isDirty = false, initialReportName = "", onLoad, onSave, onUpdate }: Props) => {
  const { userTimeZoneIdentifier } = useUserContext();
  const [isLoadReportModalOpen, setIsLoadReportModalOpen] = useState(false);
  const [isOverwriteModalOpen, setIsOverwriteModalOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState<FormData>({ reportName: initialReportName, rand: v4() });
  const [reportToOverwrite, setReportToOverwrite] = useState<ReportDefinitionSummary>();
  const [error, setError] = useState("");

  const successAnimation = useTransition(showSuccess, {
    from: { opacity: 1 },
    leave: { opacity: 0, delay: 1000 },
    onRest: () => setShowSuccess(false),
  });

  useEffect(() => {
    setFormData({ reportName: initialReportName, rand: v4() });
  }, [initialReportName]);

  const loadReportDefinition = (reportDefinition: ReportDefinition) => {
    onLoad(reportDefinition);
    setFormData({ reportName: reportDefinition.name, rand: v4() });
    setIsLoadReportModalOpen(false);
  };

  const handleSubmit = async (data: FormData) => {
    setError("");

    // Fetch fresh summaries to check for name uniqueness.
    const summaryResponse = await getReportDefinitionSummaries();

    if (summaryResponse.error) {
      setError(summaryResponse.error.join(" "));
    } else if (!summaryResponse.response?.ok) {
      setError("Server error. Failed to save report.");
    } else {
      const matchingReport = summaryResponse.data?.find(
        (rd) => rd.name.toLowerCase() === data.reportName.toLowerCase()
      );

      if (matchingReport) {
        // Show overwrite prompt if name is not unique.
        setReportToOverwrite(matchingReport);
        setIsOverwriteModalOpen(true);
      } else {
        // Save report definition if name is unique.
        const error = await onSave(data.reportName);
        if (error) {
          setError(error);
        } else {
          setShowSuccess(true);
        }
      }
    }
  };

  const handleOverwriteConfirm = async () => {
    setIsOverwriteModalOpen(false);
    const error = await onUpdate(reportToOverwrite!);
    if (error) {
      setError(error);
    } else {
      setShowSuccess(true);
    }
  };

  return (
    <>
      <Formik initialValues={formData} enableReinitialize onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ submitForm, touched, errors, initialValues, values }) => {
          const isFormDirty =
            initialReportName !== "" && (isDirty || JSON.stringify(values) !== JSON.stringify(initialValues));

          return (
            <Form className="my-3 d-flex gap-2 align-items-end">
              <Button className="text-nowrap" size="sm" onClick={() => setIsLoadReportModalOpen(true)}>
                <FontAwesomeIcon icon="file-export" /> Load
              </Button>

              <Button className="text-nowrap" size="sm" onClick={submitForm}>
                <FontAwesomeIcon icon="save" /> Save
              </Button>

              <Field
                as={NameField}
                name="reportName"
                size="sm"
                placeholder="Save report as..."
                isInvalid={touched.reportName && errors.reportName}
                className={classNames({ "is-dirty": isFormDirty, reportNameInput: true })}
              />

              {isFormDirty && (
                <span className="text-small text-muted">
                  <small>
                    <strong>
                      <FontAwesomeIcon icon="triangle-exclamation" /> <i>Report contains unsaved changes.</i>
                    </strong>
                  </small>
                </span>
              )}

              {error && (
                <span className="text-danger">
                  <FontAwesomeIcon icon="triangle-exclamation" /> {error}
                </span>
              )}

              {successAnimation((style, showSuccess) => (
                <>
                  {showSuccess && (
                    <animated.span style={style} className="text-success">
                      <strong>
                        <FontAwesomeIcon icon="check" /> Success!
                      </strong>
                    </animated.span>
                  )}
                </>
              ))}
            </Form>
          );
        }}
      </Formik>

      <LoadReportDefinitionModal
        show={isLoadReportModalOpen}
        onHide={() => setIsLoadReportModalOpen(false)}
        onLoad={loadReportDefinition}
      />

      <Modal show={isOverwriteModalOpen} onHide={() => setIsOverwriteModalOpen(false)}>
        <Modal.Header>
          <h3>Overwrite saved report?</h3>
        </Modal.Header>

        <Modal.Body>
          {reportToOverwrite && (
            <>
              <p>
                Are you sure you want to overwrite the saved report <b>"{reportToOverwrite.name}"</b>?
              </p>
              <p>
                <i>Last edited on {formatDateTimeUtc(reportToOverwrite.lastEditedUtc, userTimeZoneIdentifier)}.</i>
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setIsOverwriteModalOpen(false)}>
            <FontAwesomeIcon icon="arrow-turn-up" rotation={270} className="me-2" /> Cancel
          </Button>
          <Button onClick={handleOverwriteConfirm}>
            <FontAwesomeIcon icon="save" /> Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SaveLoadReportForm;
