import CorrelationGroup from "./CorrelationGroup";
import EventsCache from "./EventsCache";
import JobDefinition from "./JobDefinition";
import XLSX, { WorkBook } from "xlsx-js-style";
import ReportWorksheet from "./ReportWorksheet";
import Sort from "../../../../../utilities/Sort";
import RawDataWorksheet from "./RawDataWorksheet";
import ESmlcCallsWorksheet from "./ESmlcCallsWorksheet";
import ESmlcLocationsWorksheet from "./ESmlcLocationsWorksheet";
import { calculateErrorAccuracy, ErrorAccuracyType, getHorizontalAccuracy, getVerticalAccuracy, toCallExport, toLocationExport } from "./ESmlcHelpers";
import { CorrelatedESmlcRecord } from "../CorrelatedESmlcRecord";

// https://github.com/SGSIMapping/lcat_legacy/blob/30e28671bfdba384ae064cbbc12a7b64454abb99/Manager/Services/AddIns/NewReport/NewReportGenerator.cs
export default abstract class NewReportGenerator {
  public static build = (
    correlations: CorrelationGroup[],
    jobDefinition: JobDefinition,
    eSmlcEvents: CorrelatedESmlcRecord[]
  ): WorkBook => {
    const eventsCache = new EventsCache();
    const workbook = XLSX.utils.book_new();

    let rawDataWorksheet = new RawDataWorksheet();
    let reportWorksheet = new ReportWorksheet(
      jobDefinition,
    );

    for (const c of correlations) {
      rawDataWorksheet.writeData(c.toExport());

      eventsCache.cacheCorrelationGroup(
        c,
        c.isYieldExlude,
        jobDefinition.horizontalAccuracyThreshold_Meters,
        jobDefinition.verticalAccuracyThreshold_Meters
      );
    }

    reportWorksheet.writeCachedValues(eventsCache.allMsisdns);
    reportWorksheet.appendToWorkbook(workbook, "ALL MSISDN");

    const cachesInMsisdnOrder = [...eventsCache.msisdnCaches.entries()].sort((a, b) =>
      Sort.alphabeticalSort(a[0], b[0])
    );

    for (const [msisdn, eventCache] of cachesInMsisdnOrder) {
      reportWorksheet = new ReportWorksheet(
        jobDefinition,
        msisdn
      );

      if (eventCache.reportableEvent.count !== 0) {

        reportWorksheet.writeCachedValues(eventCache);
        reportWorksheet.appendToWorkbook(workbook, msisdn);
      }
    }

    // Add the raw data to the workbook. (https://github.com/SGSIMapping/lcat_legacy/blob/30e28671bfdba384ae064cbbc12a7b64454abb99/Manager/Services/AddIns/NewReport/NewReportGenerator.cs#L71)
    rawDataWorksheet.appendToWorkbook(workbook, "RawData");

    if (eSmlcEvents && eSmlcEvents.length > 0) {
      let eSmlcCallsWorksheet = new ESmlcCallsWorksheet();
      let eSmlcLocationsWorksheet = new ESmlcLocationsWorksheet();

      for (const e of eSmlcEvents) {
        e.eSmlcEvent.dbhCaError = calculateErrorAccuracy(e.eSmlcEvent, ErrorAccuracyType.DBH);
        e.eSmlcEvent.gnssCaError = calculateErrorAccuracy(e.eSmlcEvent, ErrorAccuracyType.GNSS);

        eSmlcCallsWorksheet.writeData(toCallExport(e.eSmlcEvent));

        e.eSmlcLocations.forEach((location) => {
          if (e.correlatedGpsLocation) {
            location.horizontalAccuracy = getHorizontalAccuracy(
              location.longitude,
              location.latitude,
              e.correlatedGpsLocation.longitude,
              e.correlatedGpsLocation?.latitude);
          }

          if (e.correlatedGpsLocation) {
            location.verticalAccuracy = getVerticalAccuracy(
              location.altitude,
              e.correlatedGpsLocation?.elevation);
          }

          eSmlcLocationsWorksheet.writeData(toLocationExport(location, e.correlatedGpsLocation));
        });
      }

      eSmlcCallsWorksheet.appendToWorkbook(workbook, "eSMLC_Calls");
      eSmlcLocationsWorksheet.appendToWorkbook(workbook, "eSMLC_Locations");
    }

    return workbook;
  };

  public static write = (workbook: WorkBook, fileName: string): void => {
    XLSX.writeFile(workbook, fileName);
  };
}
