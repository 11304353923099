import { TMorphologyOption } from "../../components/pages/Report/reportHelpers";

export class StaticPointAddEditRequest {
  name: string;
  latitude: number;
  longitude: number;
  elevation_Meters: number;
  morphology: TMorphologyOption;

  constructor(
    name: string,
    latitude: number,
    longitude: number,
    elevation_Meters: number,
    morphology: TMorphologyOption
  ) {
    this.name = name;
    this.latitude = latitude;
    this.longitude = longitude;
    this.elevation_Meters = elevation_Meters;
    this.morphology = morphology;
  }
}
