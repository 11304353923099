import { TimeZoneValue } from "../../utilities/timezone";

export class AddMtlrSetRequest {
  msisdn: string;
  intervalSeconds: number;
  timeZone: TimeZoneValue;
  isScheduled: boolean;
  isPaused: boolean;
  startTimeUtc?: string | null;
  endTimeUtc?: string | null;

  constructor(
    msisdn: string,
    intervalSeconds: number,
    timeZone: TimeZoneValue,
    isScheduled: boolean,
    isPaused: boolean,
    startTimeUtc?: string | null,
    endTimeUtc?: string | null,
  ) {
    this.msisdn = msisdn;
    this.startTimeUtc = startTimeUtc;
    this.endTimeUtc = endTimeUtc;
    this.intervalSeconds = intervalSeconds;
    this.timeZone = timeZone;
    this.isScheduled = isScheduled;
    this.isPaused = isPaused;
  }
}
