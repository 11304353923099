export type AppSettings = {
  azureAdClientId: string;
  azureAdAuthority: string;
  versionNumber: string;
  azureAdScopes: string[];
  horizontalAccuracyThreshold_Meters: number;
  horizontalAccuracyPercentile1: number;
  horizontalAccuracyPercentile2: number;
  latencyPercentile1: number;
  latencyPercentile2: number;
  verticalAccuracyThreshold_Meters: number;
  verticalAccuracyPercentile1: number;
  verticalAccuracyPercentile2: number;
  mtlrSetIntervalMinValueSeconds: number;
  mtlrSetIntervalMaxValueSeconds: number;
  maxGPSFileSizeInMB: number;
};

declare global {
  interface Window {
    appSettings: AppSettings;
  }
}

window.appSettings = window.appSettings || undefined;
