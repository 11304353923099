import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
  show: boolean;
  isUserCreatingUser: boolean;
  onModalDelete: (event: React.SyntheticEvent) => void;
  onModalClose?: () => void;
  creatingUserSets?: number;
  otherUserSets?: number;
};

const modalBodyText = (isUserCreatingUser: boolean, creatingUserSets?: number, otherUserSets?: number) => {
  if (creatingUserSets || otherUserSets) {
    return (
      <>
        <div className="mb-2">Are you sure you want to delete these MTLR Set(s)?</div>
        <div>Your MTLR Set(s): {creatingUserSets}</div>
        <div>Other Users' MTLR Set(s): {otherUserSets}</div>
      </>
    );
  } else if (isUserCreatingUser) {
    return (<div>Are you sure you want to delete this MTLR Set?</div>);
  } else {
    return (<div>You did not create this MTLR Set. Are you sure you want to delete it?</div>);
  }
};

const DeleteModal = ({ show, isUserCreatingUser, onModalDelete, onModalClose, creatingUserSets, otherUserSets }: Props) => {
  return (
    <Modal show={show} onHide={onModalClose}>
      <Modal.Header>
        <Modal.Title>Delete MTLR Set(s)?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalBodyText(isUserCreatingUser, creatingUserSets, otherUserSets)}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button size="sm" variant="outline-secondary" onClick={onModalClose}>
          <FontAwesomeIcon icon={icon({ name: "level-up-alt" })} className="fa-rotate-270 me-1" />{" "}
          Cancel
        </Button>
        <Button size="sm" variant="danger" onClick={onModalDelete}>
          <FontAwesomeIcon icon={icon({ name: "trash" })} className="me-1" /> Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
