export class EventRequest {
  msisdns: string[];
  startTimeUtc: string;
  endTimeUtc: string;
  mode: string;
  exportJobId: string;
  ESmlc: boolean;

  constructor(msisdns: string[], startTimeUtc: string, endTimeUtc: string, mode: string, exportJobId: string, eSmlc: boolean) {
    this.msisdns = msisdns;
    this.startTimeUtc = startTimeUtc;
    this.endTimeUtc = endTimeUtc;
    this.mode = mode;
    this.exportJobId = exportJobId;
    this.ESmlc = eSmlc;
  }
}
