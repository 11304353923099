export default class EventIdList {
  private _eventIds = new Set<number>();

  public add = (eventId: number): void => {
    this._eventIds.add(eventId);
  };

  public get count(): number {
    return this._eventIds.size;
  }

  public get ids(): Set<number> {
    return this._eventIds;
  }
}
