import { StringUtils } from "@azure/msal-browser";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, useField } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import styled from "styled-components";
import LineReader from "../../../utilities/LineReader";
import { GpsLocation } from "../Report/models/GpsLocation";
import { formatBytes, gpsLoggerHeader } from "./reportHelpers";
import { errorMessages } from "./reportValidationSchema";
import { Button, Modal } from "react-bootstrap";
import { postUploadGPSLoggerFile } from "../../../services/apiService";
import { BlockerFunction, useBlocker } from "react-router";
import { GpsLoggerFile } from "./models/GroundTruthData";

/**
 * Blank square representing an empty checkbox.
 * RO - was unable use the default empty square from fortawesome
 * as it kept turning into a single solid color, so I copied the
 * SVG I needed instead
 */
const EmptySquare = ({ className, fill = "#808080" }: { className: string; fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      width="16"
      height="16"
      className={`${className}`}
      fill={fill}
    >
      <path d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
    </svg>
  );
};

type ConfirmUploadModalProps = {
  showModal: boolean;
  closeModal: () => void;
  handleGPSFileUpload: () => Promise<void>;
};
export const ConfirmUploadModal = ({ showModal, closeModal, handleGPSFileUpload }: ConfirmUploadModalProps) => {
  return (
    <Modal show={showModal} onHide={closeModal} backdrop="static">
      <Modal.Header>
        <Modal.Title>Upload GPS Logger File</Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-5">
        <p>Any currently uploaded data that overlap this file will be overwritten.</p>
        Overlapping data are both:
        <ul>
          <li>Within the same time range</li>
          <li>For the same MSISDN</li>
        </ul>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="outline-secondary" onClick={closeModal}>
          <FontAwesomeIcon className="me-2" icon={icon({ name: "arrow-turn-up" })} rotation={270} />
          Cancel
        </Button>

        <Button onClick={handleGPSFileUpload}>
          <FontAwesomeIcon className="me-2" icon={icon({ name: "plus" })} />
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

type ConfirmLeavePageModalProps = {
  showModal: boolean;
  closeModal: () => void;
  handleConfirm: () => void;
};
export const ConfirmLeavePageModal = ({ showModal, closeModal, handleConfirm }: ConfirmLeavePageModalProps) => {
  return (
    <Modal show={showModal} onHide={closeModal} backdrop="static">
      <Modal.Header>
        <Modal.Title>Leave page?</Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-5">
        <p>
          GPS Logger file is still being uploaded. Leaving this page will cause the upload to not complete successfully.
        </p>

        <p>Are you sure you want to leave?</p>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="outline-secondary" onClick={closeModal}>
          <FontAwesomeIcon className="me-2" icon={icon({ name: "arrow-turn-up" })} rotation={270} />
          Cancel
        </Button>

        <Button onClick={handleConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};

const getColor = (props: DropContainerProps) => {
  if (props.$isDragAccept) {
    return "#00e676";
  }
  if (props.$isDragReject || props.$isInvalid) {
    return "#ff1744";
  }
  if (props.$isFocused) {
    return "#2196f3";
  }
  return "#bdbdbd";
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-gap: 9px;
`;

const InnerContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type DropContainerProps = {
  $isDragAccept: boolean;
  $isDragReject: boolean;
  $isFocused: boolean;
  $isInvalid: boolean;
};

const DropContainer = styled.div<DropContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  border-width: 2px;
  border-radius: 5px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #ebebeb;
  color: black;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const validateRecord = (gpsRecord: GpsLocation, lineNumber: number) => {
  let errors: string[] = [];

  if (!gpsRecord.msisdn) {
    errors.push(`Invalid value on line ${lineNumber}. Msisdn is missing.`);
  } else {
    if (gpsRecord.msisdn.length !== 10 || !Number(gpsRecord.msisdn)) {
      errors.push(`Invalid value on line ${lineNumber}. Msisdn must be 10 numeric digits.`);
    }
  }

  if (!gpsRecord.ascertained || isNaN(gpsRecord.ascertained)) {
    errors.push(`Invalid value on line ${lineNumber}. Date value is not valid.`);
  }

  if (!gpsRecord.longitude || isNaN(gpsRecord.longitude) || gpsRecord.longitude < -180 || gpsRecord.longitude > 0) {
    errors.push(`Invalid value on line ${lineNumber}. ${errorMessages.decimalNumber("Longitude", -180, 0)}`);
  }

  if (!gpsRecord.latitude || isNaN(gpsRecord.latitude) || gpsRecord.latitude < 0 || gpsRecord.latitude > 90) {
    errors.push(`Invalid value on line ${lineNumber}. ${errorMessages.decimalNumber("Latitude", 0, 90)}`);
  }

  if (!gpsRecord.elevation || isNaN(gpsRecord.elevation) || gpsRecord.elevation < -500 || gpsRecord.elevation > 10000) {
    errors.push(`Invalid value on line ${lineNumber}. ${errorMessages.decimalNumber("Elevation", -500, 10000)}`);
  }

  return errors;
};

type GPSLoggerFileState = (typeof gpsLoggerFileStates)[keyof typeof gpsLoggerFileStates];
const gpsLoggerFileStates = {
  noFile: {
    fileNameIcon: null,
    icon: <EmptySquare className="me-1" />,
    message: "No GPS Logger File",
    isUploadButtonDisabled: true,
  },
  validating: {
    fileNameIcon: <FontAwesomeIcon className="me-1 text-danger" icon={icon({ name: "minus-square" })} />,
    icon: <FontAwesomeIcon className="me-1" icon={icon({ name: "spinner" })} spin />,
    message: "Validating GPS Logger File",
    isUploadButtonDisabled: true,
  },
  invalid: {
    fileNameIcon: <FontAwesomeIcon className="me-1 text-danger" icon={icon({ name: "minus-square" })} />,
    icon: <FontAwesomeIcon className="me-1 text-danger" icon={icon({ name: "minus-square" })} />,
    message: "Invalid GPS Logger File",
    isUploadButtonDisabled: true,
  },
  tooLarge: {
    fileNameIcon: <FontAwesomeIcon className="me-1 text-success" icon={icon({ name: "square-check" })} />,
    icon: <FontAwesomeIcon className="me-1 text-danger" icon={icon({ name: "minus-square" })} />,
    message: `File too large to upload (Limited to ${window.appSettings?.maxGPSFileSizeInMB} MB)`,
    isUploadButtonDisabled: true,
  },
  valid: {
    fileNameIcon: <FontAwesomeIcon className="me-1 text-success" icon={icon({ name: "square-check" })} />,
    icon: <FontAwesomeIcon className="me-1 text-success" icon={icon({ name: "square-check" })} />,
    message: "GPS Logger File can be uploaded",
    isUploadButtonDisabled: false,
  },
  uploading: {
    fileNameIcon: <FontAwesomeIcon className="me-1 text-success" icon={icon({ name: "square-check" })} />,
    icon: <FontAwesomeIcon className="me-1" icon={icon({ name: "spinner" })} spin />,
    message: "Uploading GPS Logger File",
    isUploadButtonDisabled: true,
  },
  saved: {
    fileNameIcon: <FontAwesomeIcon className="me-1 text-success" icon={icon({ name: "square-check" })} />,
    icon: <FontAwesomeIcon className="me-1 text-success" icon={icon({ name: "square-check" })} />,
    message: "GPS Logger File Saved",
    isUploadButtonDisabled: true,
  },
  serverError: {
    fileNameIcon: <FontAwesomeIcon className="me-1 text-success" icon={icon({ name: "square-check" })} />,
    icon: <FontAwesomeIcon className="me-1 text-danger" icon={icon({ name: "minus-square" })} />,
    message: "Server Error",
    isUploadButtonDisabled: false,
  },
};

type GpsLoggerProps = {
  name: string;
};
const GroundTruthGpsLogger = ({ name }: GpsLoggerProps) => {
  const [field, meta, helpers] = useField<GpsLoggerFile>(name);
  const [validationErrorMessages, setValidationErrorMessages] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [gpsLoggerFileState, setGPSLoggerFileState] = useState<GPSLoggerFileState>(gpsLoggerFileStates.noFile);

  const isUploading = gpsLoggerFileState.message === gpsLoggerFileStates.uploading.message;

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => isUploading && currentLocation.pathname !== nextLocation.pathname,
    [isUploading]
  );

  const pageBlocker = useBlocker(shouldBlock);

  const validateFileDropped = useCallback(async (file: File) => {
    const lineReader = new LineReader(file);
    const maxLines = 11;
    let lineCount = -1;
    let gps: GpsLocation | null = null;
    let errors: string[] = [];

    const fileSizeInMB = file.size / (1_024 * 1_024);
    if (!!window.appSettings?.maxGPSFileSizeInMB && fileSizeInMB > window.appSettings.maxGPSFileSizeInMB) {
      setGPSLoggerFileState(gpsLoggerFileStates.tooLarge);
      return;
    }

    for await (const line of lineReader.forEachLine()) {
      let recordErrors: string[] = [];
      lineCount++;

      if (lineCount < maxLines) {
        //validate the first line is the header
        if (lineCount === 0) {
          //if header is userId, msisdn make it userId,msisdn
          const trimmedArray = StringUtils.trimArrayEntries(line.split(","));

          if (JSON.stringify(trimmedArray).toLowerCase() !== JSON.stringify(gpsLoggerHeader).toLowerCase()) {
            errors.push("Header values are incorrect for a GPS Logger file.");
            break;
          }
        } else {
          try {
            gps = GpsLocation.parse(line, false);
            recordErrors = validateRecord(gps, lineCount + 1);
          } catch {
            errors.push(`Invalid gps record on line ${lineCount + 1}. Data could not be parsed.`);
          }

          if (recordErrors.length > 0) {
            errors.push(...recordErrors);
          }
        }
      } else {
        // done validating, hit max lines
        break;
      }
    }

    if (lineCount < 0) {
      errors.push("Invalid file it is empty.");
    }

    if (errors.length > 0) {
      setValidationErrorMessages(errors);
      setGPSLoggerFileState(gpsLoggerFileStates.invalid);
      return;
    }

    setGPSLoggerFileState(gpsLoggerFileStates.valid);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setGPSLoggerFileState(gpsLoggerFileStates.validating);

      if (fileRejections.length > 0) {
        setValidationErrorMessages(["Only 1 file of type txt/csv can be used."]);
        setGPSLoggerFileState(gpsLoggerFileStates.invalid);
        helpers.setValue(fileRejections[0].file);
      } else {
        helpers.setValue(acceptedFiles[0]);

        setValidationErrorMessages([]);
        validateFileDropped(acceptedFiles[0]);
      }
    },
    [helpers, validateFileDropped]
  );

  const {
    getRootProps,
    getInputProps,
    isFocused: $isFocused,
    isDragAccept: $isDragAccept,
    isDragReject: $isDragReject,
  } = useDropzone({
    disabled: isUploading,
    onDrop,
    maxFiles: 1,
    accept: {
      "text/csv": [".csv"],
      "text/plain": [".txt"],
    },
  });

  const handleGPSFileUpload = async () => {
    if (!field.value) {
      return;
    }

    try {
      setShowModal(false);
      setGPSLoggerFileState(gpsLoggerFileStates.uploading);

      const response = await postUploadGPSLoggerFile(field.value);

      if (response.hasError || response?.response?.status === 500) {
        if (response.error) {
          console.error(response.error.join(" "));
        }

        setGPSLoggerFileState(gpsLoggerFileStates.serverError);
      } else {
        setGPSLoggerFileState(gpsLoggerFileStates.saved);
      }
    } catch (error) {
      console.error(error);
      setGPSLoggerFileState(gpsLoggerFileStates.serverError);
    }
  };

  const preventRefresh = (e: BeforeUnloadEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (isUploading) {
      window.addEventListener("beforeunload", preventRefresh);
    }

    return () => {
      window.removeEventListener("beforeunload", preventRefresh);
    };
  }, [isUploading]);

  return (
    <div>
      <Container>
        <DropContainer
          {...getRootProps({
            $isFocused,
            $isDragAccept,
            $isDragReject,
            $isInvalid: meta.error !== undefined && meta.touched,
          })}
        >
          <input {...getInputProps()} />
          <span>{isUploading ? "Upload in progress" : "Drop here or click to select a file"}</span>
        </DropContainer>

        {field.value && (
          <aside>
            <div>
              {gpsLoggerFileState.fileNameIcon}
              <strong>File name:</strong> {field.value.path} {field.value.size && `(${formatBytes(field.value.size)})`}
            </div>
          </aside>
        )}

        <InnerContainer>
          <Button
            className="me-2"
            size="sm"
            disabled={gpsLoggerFileState.isUploadButtonDisabled}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon className="me-1" icon={icon({ name: "upload" })} />
            Upload for Future Use
          </Button>

          <span>
            {gpsLoggerFileState.icon}
            {gpsLoggerFileState.message}
          </span>
        </InnerContainer>
      </Container>

      <MessageContainer id="messageDiv" className="mt-1">
        {validationErrorMessages.map((message) => (
          <span key={message} className="text-danger small">
            {message}
          </span>
        ))}
        {meta.error && meta.touched && (
          <div className="text-danger small">
            <ErrorMessage name={field.name} />
          </div>
        )}
      </MessageContainer>

      <ConfirmUploadModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        handleGPSFileUpload={handleGPSFileUpload}
      />

      <ConfirmLeavePageModal
        showModal={pageBlocker.state === "blocked"}
        closeModal={() => (pageBlocker.reset ? pageBlocker.reset() : null)}
        handleConfirm={() => (pageBlocker.proceed ? pageBlocker.proceed() : null)}
      />
    </div>
  );
};

export default GroundTruthGpsLogger;
