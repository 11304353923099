import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICellRendererParams } from "ag-grid-community";
import { ErrorMessage, Field, useField } from "formik";
import { MouseEvent, useEffect, useState } from "react";
import { Button, Form, FormLabel } from "react-bootstrap";
import styled from "styled-components";
import { useReportContext } from "../../../contexts/ReportContext";
import { patchStaticPoint, postAddStaticPoint } from "../../../services/apiService";
import { StaticPoint } from "../../../services/models/StaticPoint";
import { StaticPointAddEditRequest } from "../../../services/requests/StaticPointAddEditRequest";
import { StaticPointPatchRequest } from "../../../services/requests/StaticPointPatchRequest";
import Dropdown, { DropdownOption } from "../shared/Dropdown";
import NumberInput from "../shared/NumberInput";
import { StaticPointsModal } from "./StaticPoints/StaticPointsModal";
import { MorphologyOption } from "./reportHelpers";
import { theme } from "../../../utilities/constants";

export const customSelectStyles = (error: boolean) => {
  return {
    /* styles for input box for msisdn entry */
    control: (provided: any) => ({
      ...provided,
      border: error ? "1px solid red" : "",
      minWidth: "130px",
    }),
    /* change the spacing of the items in the menulist */
    option: (provided: any) => ({
      ...provided,
      paddingTop: "1px",
      paddingBottom: "1px",
    }),
  };
};

type MessageProps = {
  $isError?: boolean;
};
const Message = styled.span<MessageProps>`
  color: ${(props) => (props.$isError ? "red" : theme.colors.green)};
  margin-left: 10px;
`;

const FieldContainer = styled.div`
  display: flex;
  grid-column-gap: 10px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  grid-column-gap: 15px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  align-items: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextInput = styled(Form.Control)`
  width: 300px;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

export const morphologyOptions: DropdownOption[] = [
  {
    label: MorphologyOption.Outdoor,
    value: MorphologyOption.Outdoor,
  },
  {
    label: MorphologyOption.Indoor,
    value: MorphologyOption.Indoor,
  },
  {
    label: MorphologyOption.Mild_Indoor,
    value: MorphologyOption.Mild_Indoor,
  },
  {
    label: MorphologyOption.Deep_Indoor,
    value: MorphologyOption.Deep_Indoor,
  },
] as const;

type Props = {
  latitudeFieldName: string;
  longitudeFieldName: string;
  elevationFieldName: string;
  morphologyFieldName: string;
  staticPointFieldName: string;
};

const GroundTruthStatic = ({
  latitudeFieldName,
  longitudeFieldName,
  elevationFieldName,
  morphologyFieldName,
  staticPointFieldName,
}: Props) => {
  const [latitudeField, latitudeMeta, latitudeHelpers] = useField(latitudeFieldName);
  const [longitudeField, longitudeMeta, longitudeHelpers] = useField(longitudeFieldName);
  const [elevationField, elevationMeta, elevationHelpers] = useField(elevationFieldName);
  const [morphologyField, morphologyMeta, morphologyHelpers] = useField(morphologyFieldName);
  const [staticPointField, staticPointMeta, staticPointHelpers] = useField(staticPointFieldName);

  const [showStaticPointsModal, setShowStaticPointsModal] = useState<boolean>(false);
  const [isSavingStaticPoint, setIsSavingStaticPoint] = useState<boolean>(false);

  const {
    groundTruthStaticMessageState,
    setGroundTruthStaticMessage,
    setLoadedStaticPointId,
    isSaveAsButtonDisabled,
    setIsSaveAsButtonDisabled,
  } = useReportContext();

  const isMorphologyInError = morphologyMeta.error !== undefined && morphologyMeta.touched;

  useEffect(() => {
    setGroundTruthStaticMessage("");
    setIsSaveAsButtonDisabled(false);
  }, [latitudeField.value, longitudeField.value, elevationField.value, morphologyField.value, staticPointField.value]);

  const handleSaveStaticPoint = async () => {
    try {
      setIsSavingStaticPoint(true);

      // validate fields
      latitudeHelpers.setTouched(true);
      longitudeHelpers.setTouched(true);
      elevationHelpers.setTouched(true);
      morphologyHelpers.setTouched(true);

      // check if any of the fields have errors
      if (latitudeMeta.error || longitudeMeta.error || elevationMeta.error || morphologyMeta.error) {
        return;
      }

      const staticPointRequest = new StaticPointAddEditRequest(
        staticPointField.value,
        latitudeField.value,
        longitudeField.value,
        elevationField.value,
        morphologyField.value
      );

      const response = await postAddStaticPoint(staticPointRequest);

      if (response.hasError || !response.data) {
        if (response.error) {
          setGroundTruthStaticMessage(response.error.join(" "), true);
        } else {
          setGroundTruthStaticMessage("Failed to save static point", true);
        }
      } else {
        setIsSaveAsButtonDisabled(true);
        setGroundTruthStaticMessage("Saved");
      }
    } finally {
      setIsSavingStaticPoint(false);
    }
  };

  const handleLoadStaticPoint = async (e: MouseEvent<HTMLButtonElement>, params: ICellRendererParams<StaticPoint>) => {
    if (!params.data) {
      return;
    }

    setLoadedStaticPointId(params.data.id);

    // populate static point fields - name, lat, long, morph, elevation
    staticPointHelpers.setValue(params.data.name);
    latitudeHelpers.setValue(params.data.latitude);
    longitudeHelpers.setValue(params.data.longitude);
    elevationHelpers.setValue(params.data.elevation_Meters);
    morphologyHelpers.setValue(params.data.morphology);

    // validate
    latitudeHelpers.setTouched(true);
    longitudeHelpers.setTouched(true);
    elevationHelpers.setTouched(true);
    morphologyHelpers.setTouched(true);

    // update lastUsedUtc
    const staticPointPatchRequest = new StaticPointPatchRequest();
    const response = await patchStaticPoint(params.data.id, staticPointPatchRequest);

    // close modal
    setShowStaticPointsModal(false);

    if (response.hasError || !response.data) {
      if (response.error) {
        setGroundTruthStaticMessage(response.error.join(" "), true);
      } else {
        setGroundTruthStaticMessage("Failed to load static point", true);
      }
    } else {
      setIsSaveAsButtonDisabled(true);
      setGroundTruthStaticMessage("Saved");
    }
  };

  return (
    <div>
      <ButtonContainer>
        {/* the below is throwing a compile error: "Expression produces a union type that is too complex to represent" */}
        {/* @ts-ignore */}
        <StyledButton size="sm" className="text-nowrap" onClick={() => setShowStaticPointsModal(true)}>
          <FontAwesomeIcon icon={icon({ name: "file-export" })} className="me-1" />
          <span>Load</span>
        </StyledButton>

        <StyledButton size="sm" className="text-nowrap" onClick={handleSaveStaticPoint} disabled={isSaveAsButtonDisabled}>
          {isSavingStaticPoint ? (
            <FontAwesomeIcon icon={icon({ name: "spinner" })} spin className="me-1" />
          ) : (
            <FontAwesomeIcon icon={icon({ name: "floppy-disk" })} className="me-1" />
          )}
          <span>Save as</span>
        </StyledButton>

        <Field
          as={StyledTextInput}
          name={staticPointFieldName}
          type="text"
          placeholder="Save static point as..."
          maxLength={50}
          size="sm"
        />

        {groundTruthStaticMessageState.message && (
          <Message $isError={groundTruthStaticMessageState.isError}>
            <span>{groundTruthStaticMessageState.message}</span>
          </Message>
        )}
      </ButtonContainer>

      <FieldContainer>
        <div>
          <FormLabel htmlFor={latitudeFieldName}>Latitude</FormLabel>
          <NumberInput id={latitudeFieldName} name={latitudeFieldName} regex={/^[-.\d]*$/} />
        </div>

        <div>
          <FormLabel htmlFor={longitudeFieldName}>Longitude</FormLabel>
          <NumberInput id={longitudeFieldName} name={longitudeFieldName} regex={/^[-.\d]*$/} />
        </div>

        <div>
          <FormLabel htmlFor={elevationFieldName}>Elevation (m)</FormLabel>
          <NumberInput id={elevationFieldName} name={elevationFieldName} regex={/^[-.\d]*$/} />
        </div>

        <div>
          <div>
            <FormLabel htmlFor={morphologyFieldName}>Morphology</FormLabel>
          </div>
          <Dropdown
            id={morphologyFieldName}
            name={morphologyFieldName}
            options={morphologyOptions}
            styles={customSelectStyles(isMorphologyInError)}
          />
        </div>
      </FieldContainer>

      <ErrorContainer>
        {latitudeMeta.error && latitudeMeta.touched && (
          <div className="text-danger small">
            <ErrorMessage name={latitudeFieldName} />
          </div>
        )}
        {longitudeMeta.error && longitudeMeta.touched && (
          <div className="text-danger small">
            <ErrorMessage name={longitudeFieldName} />
          </div>
        )}
        {elevationMeta.error && elevationMeta.touched && (
          <div className="text-danger small">
            <ErrorMessage name={elevationFieldName} />
          </div>
        )}
        {morphologyMeta.error && morphologyMeta.touched && (
          <div className="text-danger small">
            <ErrorMessage name={morphologyFieldName} />
          </div>
        )}
      </ErrorContainer>

      <StaticPointsModal
        showModal={showStaticPointsModal}
        hideModal={() => setShowStaticPointsModal(false)}
        handleLoadStaticPoint={handleLoadStaticPoint}
      />
    </div>
  );
};

export default GroundTruthStatic;
