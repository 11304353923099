import queryString from "query-string";
import { acquireAccessToken } from "../azure/helpers";
import { StaticPoint } from "./models/StaticPoint";
import { AddMtlrSetRequest } from "./requests/AddMtlrSetRequest";
import { PauseMtlrSetRequest } from "./requests/PauseMtlrSetRequest";
import { EventRequest } from "./requests/EventRequest";
import { GpsFileRequest } from "./requests/GpsFileRequest";
import { StaticPointAddEditRequest } from "./requests/StaticPointAddEditRequest";
import { StaticPointPatchRequest } from "./requests/StaticPointPatchRequest";
import AllowListMsisdnsResponse from "./responses/AllowListMsisdnsResponse";
import { ApiResponse } from "./responses/ApiResponse";
import EventResponse from "./responses/EventResponse";
import GetMtlrSetResponse from "./responses/GetMtlrSetResponse";
import { GpsFileSummaryResponse } from "./responses/GpsFileSummaryResponse";
import MtlrSetResponse from "./responses/MtlrSetResponse";
import MtlrSetsResponse from "./responses/MtlrSetsResponse";
import PermissionsResponse from "./responses/PermissionsResponse";

export const getMtlrSets = () => makeGetRequest<MtlrSetsResponse>("/api/mtlr-sets");

export const pauseMtlrSet = (
  id: number,
  pauseMtlrSetRequest: PauseMtlrSetRequest
): Promise<ApiResponse<MtlrSetResponse | null>> => makePostRequest(`/api/mtlr-sets/${id}/pause`, pauseMtlrSetRequest);

export const startMtlrSet = (id: number): Promise<ApiResponse<MtlrSetResponse | null>> =>
  makePostRequest(`/api/mtlr-sets/${id}/start`, null);

export const stopMtlrSet = (id: number): Promise<ApiResponse<MtlrSetResponse | null>> =>
  makePostRequest(`/api/mtlr-sets/${id}/stop`, null);

//when getting permissions always refresh the access token, there is a lag between permissions being
//  removed and it being reflected when acquiring the token that we can't control.
export const getPermissions = () => makeGetRequest<PermissionsResponse>("/api/permissions", true);

export const getAllowListMsisdns = () => makeGetRequest<AllowListMsisdnsResponse>("/api/allow-list/msisdns");

export const getEvents = (request: EventRequest, abortSignal: AbortSignal) =>
  makeGetRequest<EventResponse>("/api/events", false, request, abortSignal);

export const getMtlrSet = (id: number) => makeGetRequest<GetMtlrSetResponse>(`/api/mtlr-sets/${id}`);

export const deleteMtlrSet = (id: number): Promise<ApiResponse<null>> => makeDeleteRequest(`/api/mtlr-sets/${id}`);

export const cancelJob = (jobId: string): Promise<ApiResponse<null>> => makeDeleteRequest(`/api/jobs/${jobId}`);

export const progressJob = (jobId: string): Promise<ApiResponse<null>> => makeGetRequest(`/api/jobs/${jobId}/status`);

export const postAddMtlrSet = (addMtlrRequest: AddMtlrSetRequest): Promise<ApiResponse<MtlrSetResponse | null>> =>
  makePostRequest("api/mtlr-sets", addMtlrRequest);

export const postAddStaticPoint = (
  staticPointAddEditRequest: StaticPointAddEditRequest
): Promise<ApiResponse<StaticPoint | null>> => makePostRequest("api/static-points", staticPointAddEditRequest);

export const patchStaticPoint = (
  staticPointId: number,
  staticPointPatchRequest: StaticPointPatchRequest
): Promise<ApiResponse<StaticPoint | null>> =>
  makeApiRequest(`api/static-points/${staticPointId}`, "PATCH", staticPointPatchRequest);

export const getStaticPoints = (): Promise<ApiResponse<StaticPoint[] | null>> => makeGetRequest("api/static-points");

export const putStaticPoint = (
  staticPointId: number,
  staticPointAddEditRequest: StaticPointAddEditRequest
): Promise<ApiResponse<StaticPoint | null>> =>
  makeApiRequest(`api/static-points/${staticPointId}`, "PUT", staticPointAddEditRequest);

export const deleteStaticPoint = (staticPointId: number) => makeDeleteRequest(`api/static-points/${staticPointId}`);

export const postUploadGPSLoggerFile = (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return makePostRequest("/api/gps-files", null, formData);
};

export const getGpsFileSummary = (request: GpsFileRequest) =>
  makeGetRequest<GpsFileSummaryResponse>("/api/gps-files/summary", false, request);

export const getGpsFiles = (request: GpsFileRequest, abortSignal?: AbortSignal) =>
  makeGetRequest<any>("/api/gps-files", false, request, abortSignal, true);

export const makeGetRequest = async <TData>(
  url: string,
  forceRefresh: boolean = false,
  params?: any,
  abortSignal?: AbortSignal,
  isFileResponse: boolean = false
): Promise<ApiResponse<TData | null>> => {
  try {
    if (params) {
      url += `?${queryString.stringify(params)}`;
    }
    const token = await acquireAccessToken(forceRefresh);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        ...(isFileResponse ? {} : { "Content-Type": "application/json" }),
        Authorization: `Bearer ${token}`,
      },
      signal: abortSignal,
    });

    return await handleResponse(response, isFileResponse);
  } catch (error: any) {
    //don't write an error to the console if the request was aborted
    // (this can occur on the report page when the user clicks cancel)
    if (error.name !== "AbortError") {
      console.error(`${error.name} ${error.errorCode} ${error.subError} ${error.correlationId}`);
    }
    return Promise.resolve(new ApiResponse(null, null, [error]));
  }
};

export const makeDeleteRequest = async <TData>(url: string): Promise<ApiResponse<TData | null>> => {
  try {
    //always get new token before delete call
    const token = await acquireAccessToken(true);
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return await handleResponse(response);
  } catch (error: any) {
    console.error(`${error.name} ${error.errorCode} ${error.subError} ${error.correlationId}`);
    return Promise.resolve(new ApiResponse(null, null, [error]));
  }
};

export const makePostRequest = async <TData, TBody>(
  url: string,
  body: TBody,
  formData?: FormData
): Promise<ApiResponse<TData | null>> => {
  try {
    const token = await acquireAccessToken();
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...(formData ? {} : { "Content-Type": "application/json" }),
        Authorization: `Bearer ${token}`,
      },
      body: formData ?? JSON.stringify(body),
    });

    return await handleResponse(response);
  } catch (error: any) {
    console.error(`${error.name} ${error.errorCode} ${error.subError} ${error.correlationId}`);
    return Promise.resolve(new ApiResponse(null, null, [error]));
  }
};

export const makePatchRequest = async <TData, TBody>(url: string, body?: TBody): Promise<ApiResponse<TData | null>> => {
  try {
    const token = await acquireAccessToken();
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body ? JSON.stringify(body) : "",
    });

    return await handleResponse(response);
  } catch (error: any) {
    console.error(`${error.name} ${error.errorCode} ${error.subError} ${error.correlationId}`);
    return Promise.resolve(new ApiResponse(null, null, [error]));
  }
};

export const makePutRequest = async <TData, TBody>(url: string, body?: TBody): Promise<ApiResponse<TData | null>> => {
  try {
    const token = await acquireAccessToken();
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body ? JSON.stringify(body) : "",
    });

    return await handleResponse(response);
  } catch (error: any) {
    console.error(`${error.name} ${error.errorCode} ${error.subError} ${error.correlationId}`);
    return Promise.resolve(new ApiResponse(null, null, [error]));
  }
};

export const makeApiRequest = async <TData, TBody>(
  url: string,
  method: "POST" | "PATCH" | "PUT",
  body?: TBody
): Promise<ApiResponse<TData | null>> => {
  try {
    const token = await acquireAccessToken();
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    return await handleResponse(response);
  } catch (error: any) {
    console.error(`${error.name} ${error.errorCode} ${error.subError} ${error.correlationId}`);
    return Promise.resolve(new ApiResponse(null, null, [error]));
  }
};

const handleResponse = async <TData>(
  response: any,
  isFileResponse: boolean = false
): Promise<ApiResponse<TData | null>> => {
  try {
    if (response.ok) {
      const data = await response;

      if (isFileResponse) {
        const file = data.body;
        return new ApiResponse(response, file, null);
      }

      //no content response
      if (response.status === 204) {
        return new ApiResponse(response, null, null);
      }

      const jsonValue: TData = await data.json();

      return new ApiResponse(response, jsonValue, null);
    } else {
      //this is an unexpected status but if returned there does not appear to be json
      if (response.status === 500) {
        return new ApiResponse(response, null, [response.statusText || "Internal Server Error"]);
      }

      let error = await response.text();
      if (!error && response.status) {
        error = `API Request received a status code of ${response.status}. Was unable to get a valid response from API.`;
      }

      // return a generic error if no error text was received
      if (!error && response.status != null) {
        return new ApiResponse(response, null, [`An error occurred. (${response.status})`]);
      } else {
        try {
          // try to parse the error as json
          const jsonError = JSON.parse(error);

          return new ApiResponse(response, null, [jsonError]);
        } catch (e) {
          // the error was not json so just return text as an array
          return new ApiResponse(response, null, error ? [error] : []);
        }
      }
    }
  } catch (error: any) {
    console.log(error);
    return new ApiResponse(null, null, [error]);
  }
};
