import { CellEditRequestEvent, ColDef } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { Button, Modal, ModalProps } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import ReportDefinitionSummary from "../../../../services/models/ReportDefinitions/ReportDefinitionSummary";
import FormSwitch from "../../shared/FormSwitch";
import { useUserContext } from "../../../../contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { appPermissions } from "../../../auth/roles";
import { validateReportDefinitionName } from "./loadReportDefinitionValidation";
import ActionButtonRenderer from "./ActionButtonRenderer";
import styled from "styled-components";
import { getReportDefinitionSummaries, updateReportDefinitionName } from "../../../../services/reportDefinitionService";
import ReportDefinition from "../../../../services/models/ReportDefinitions/ReportDefinition";
import { formatDateTimeUtc } from "../../shared/formatters";

import "./LoadReportDefinitionModal.scss";

type Props = {
  onLoad: (reportDefinition: ReportDefinition) => void;
} & ModalProps;

const GridWrapper = styled.div`
  height: 500px;
  font-size: 14px;

  & .ag-row {
    overflow: visible !important;
  }

  & .ag-cell-value {
    overflow: visible !important;
  }

  & .ag-row.ag-row-focus {
    z-index: 1;
  }
`;

const LoadReportDefinitionModal = ({ onLoad, ...props }: Props) => {
  const [rowData, setRowData] = useState<ReportDefinitionSummary[]>([]);
  const [onlyShowUserReports, setOnlyShowUserReports] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { currentUser, userHasAppPermission, userTimeZoneIdentifier } = useUserContext();

  useEffect(() => {
    if (props.show) {
      setOnlyShowUserReports(true);
      fetchSummaries();
    }
  }, [props.show]);

  const fetchSummaries = async () => {
    try {
      setError("");
      setIsLoading(true);

      var result = await getReportDefinitionSummaries();

      if (result.error) {
        setError(result.error.join("\n"));
        console.error(result.error);
      } else if (!result.response?.ok) {
        setError("Server error. Failed to fetch reports.");
      }

      if (result.data) {
        setRowData(result.data);
      } else {
        setRowData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const close = () => props.onHide && props.onHide();

  const isCellEditable = (owner: string) =>
    owner === currentUser?.localAccountId || userHasAppPermission(appPermissions.reportPage.reportDefinitions.editName);

  const colDefs = useMemo<ColDef<ReportDefinitionSummary>[]>(
    () => [
      {
        colId: "actions",
        field: "id",
        headerName: "",
        width: 65,
        resizable: false,
        cellRenderer: ActionButtonRenderer,
        cellRendererParams: {
          onLoad: (reportDefinition: ReportDefinition) => {
            onLoad(reportDefinition);
          },
          onDelete: (id: number) => {
            setRowData((prev) => prev.filter((row) => row.id !== id));
          },
          onError: (errors: string[]) => {
            setError(errors.join("\n"));
          },
        },
      },
      {
        field: "name",
        headerName: "Name",
        editable: (params) => isCellEditable(params.data?.owner ?? ""),
        cellClassRules: {
          "is-editable": (params) => isCellEditable(params.data?.owner ?? ""),
        },
      },
      { field: "ownerDisplayName", headerName: "Owner" },
      {
        field: "lastEditedUtc",
        headerName: "Last Edited",
        sort: "desc",
        cellRenderer: (props: CustomCellRendererProps<ReportDefinitionSummary>) =>
          formatDateTimeUtc(props.value, userTimeZoneIdentifier),
      },
    ],
    [userTimeZoneIdentifier]
  );

  const filteredRowData = useMemo(
    () => rowData.filter((r) => (onlyShowUserReports ? r.owner === currentUser?.localAccountId : true)),
    [onlyShowUserReports, currentUser, rowData]
  );

  const handleCellEditRequest = async (e: CellEditRequestEvent<ReportDefinitionSummary, string>) => {
    if (e.colDef.field === "name" && e.newValue) {
      const nameValidationResult = validateReportDefinitionName(e.newValue);
      if (nameValidationResult) {
        setError(nameValidationResult);
      } else {
        const response = await updateReportDefinitionName(e.data.id, e.newValue);

        if (response.error) {
          setError(response.error.join("\n"));
        } else if (!response.data) {
          setError("Failed to update report name.");
        } else {
          setError("");
          setRowData((prev) =>
            prev.map((row) =>
              row.id === e.data.id ? { ...row, name: e.newValue!, lastEditedUtc: response.data!.lastEditedUtc } : row
            )
          );
          e.api.ensureNodeVisible((row) => row.data?.id === e.data.id);
        }
      }
    }
  };

  const tableText = isLoading ? "Loading reports..." : error ? error : "No saved reports.";

  return (
    <Modal size="xl" backdrop="static" keyboard={false} {...props}>
      <Modal.Header>
        <Modal.Title>Saved Reports</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormSwitch
            id="only-show-user-reports-toggle"
            label="Show only my reports"
            checked={onlyShowUserReports}
            onChange={(e) => setOnlyShowUserReports(e.target.checked)}
          />
        </div>
        <GridWrapper className="ag-theme-balham" id="load-report-definition-grid">
          <AgGridReact
            rowData={filteredRowData}
            columnDefs={colDefs}
            getRowId={(params) => params.data.id.toString()}
            autoSizeStrategy={{ type: "fitGridWidth", columnLimits: [{ colId: "actions", maxWidth: 65 }] }}
            overlayNoRowsTemplate={tableText}
            singleClickEdit
            readOnlyEdit
            onCellEditRequest={handleCellEditRequest}
          />
        </GridWrapper>
        <div>
          {error && (
            <small className="text-danger">
              <FontAwesomeIcon icon="triangle-exclamation" /> {error}
            </small>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button size="sm" variant="outline-secondary" onClick={close}>
          <FontAwesomeIcon icon="arrow-turn-up" rotation={270} className="me-2" />
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoadReportDefinitionModal;
