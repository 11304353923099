import { ESmlcEvent } from "../../../../services/models/ESmlcEvent";
import { Event } from "../../../../services/models/Event";
import { GroundTruthData } from "./GroundTruthData";

export class EventGroundTruth {
  batchSetId: number;
  events: Event[];
  eSmlcEvents: ESmlcEvent[];
  groundTruthData: GroundTruthData;

  constructor(
    batchSetId: number,
    events: Event[],
    eSmlcEvents: ESmlcEvent[],
    groundTruthData: GroundTruthData
  ) {
    this.batchSetId = batchSetId;
    this.events = events;
    this.eSmlcEvents = eSmlcEvents;
    this.groundTruthData = groundTruthData;
  };
}