export class GpsFileRequest {
  msisdns: string[];
  startTimeUtc: string;
  endTimeUtc: string;

  constructor(msisdns: string[], startTimeUtc: string, endTimeUtc: string) {
    this.msisdns = msisdns;
    this.startTimeUtc = startTimeUtc;
    this.endTimeUtc = endTimeUtc;
  }
}
