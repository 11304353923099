import * as yup from "yup";
import { StaticPoint } from "../../../../services/models/StaticPoint";
import { errorMessages } from "../reportValidationSchema";

export type StaticPointField = {
  initialValue?: any;
  newValue?: any;
  errorMessage?: string;
};
export type StaticPointKey = keyof StaticPoint;

export type StaticPointRowState = { [key in StaticPointKey]: StaticPointField };

export const staticPointsValidationSchema = yup.object().shape({
  latitude: yup.object().shape({
    newValue: yup
      .number()
      .min(0, errorMessages.decimalNumber("Latitude", 0, 90))
      .max(90, errorMessages.decimalNumber("Latitude", 0, 90))
      .required(errorMessages.requiredWithValues("Latitude", 0, 90)),
  }),
  longitude: yup.object().shape({
    newValue: yup
      .number()
      .min(-180, errorMessages.decimalNumber("Longitude", -180, 0))
      .max(0, errorMessages.decimalNumber("Longitude", -180, 0))
      .required(errorMessages.requiredWithValues("Longitude", -180, 0)),
  }),
  elevation_Meters: yup.object().shape({
    newValue: yup
      .number()
      .min(-500, errorMessages.decimalNumber("Elevation (meters)", -500, 10_000))
      .max(10_000, errorMessages.decimalNumber("Elevation (meters)", -500, 10_000))
      .required(errorMessages.requiredWithValues("Elevation (meters)", -500, 10_000)),
  }),
  morphology: yup.object().shape({
    newValue: yup.string().required(errorMessages.required("Morphology")),
  }),
});

export const buildRowState = (staticPoint?: StaticPoint): StaticPointRowState => {
  return {
    id: {
      initialValue: staticPoint?.id ?? null,
      newValue: staticPoint?.id ?? null,
      errorMessage: "",
    },
    user: {
      initialValue: staticPoint?.user ?? null,
      newValue: staticPoint?.user ?? null,
      errorMessage: "",
    },
    name: {
      initialValue: staticPoint?.name ?? null,
      newValue: staticPoint?.name ?? null,
      errorMessage: "",
    },
    lastUsedUtc: {
      initialValue: staticPoint?.lastUsedUtc ?? null,
      newValue: staticPoint?.lastUsedUtc ?? null,
      errorMessage: "",
    },
    latitude: {
      initialValue: staticPoint?.latitude ?? null,
      newValue: staticPoint?.latitude ?? null,
      errorMessage: "",
    },
    longitude: {
      initialValue: staticPoint?.longitude ?? null,
      newValue: staticPoint?.longitude ?? null,
      errorMessage: "",
    },
    elevation_Meters: {
      initialValue: staticPoint?.elevation_Meters ?? null,
      newValue: staticPoint?.elevation_Meters ?? null,
      errorMessage: "",
    },
    morphology: {
      initialValue: staticPoint?.morphology ?? null,
      newValue: staticPoint?.morphology ?? null,
      errorMessage: "",
    },
  };
};

export const getValidatedRowState = async (rowState: StaticPointRowState) => {
  const updatedRowState = { ...rowState };

  try {
    await staticPointsValidationSchema.validate(updatedRowState, { abortEarly: false });
  } catch (error: any) {
    const validationError: yup.ValidationError = error;

    for (const inner of validationError.inner) {
      const splitPath = inner.path?.split("[");

      if (splitPath) {
        const properties = splitPath[0].split(".");

        const staticPointKey = properties[0] as StaticPointKey;
        updatedRowState[staticPointKey].errorMessage = inner.message;
      }
    }
  } finally {
    return updatedRowState;
  }
};
