import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
  show: boolean;
  onModalStop: (event: React.SyntheticEvent) => void;
  onModalClose?: () => void;
};

const StopModal = ({ show, onModalStop, onModalClose }: Props) => {
  return (
    <Modal show={show} onHide={onModalClose}>
      <Modal.Header>
        <Modal.Title>Stop On-Demand MTLR Set(s)?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to stop selected On-Demand Set(s)?<div className="mt-2">Stats for these runs will be cleared on the next Start.</div> 
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button size="sm" variant="outline-secondary" onClick={onModalClose}>
          <FontAwesomeIcon icon={icon({ name: "level-up-alt" })} className="fa-rotate-270 me-1" />{" "}
          Cancel
        </Button>
        <Button size="sm" variant="danger" onClick={onModalStop}>
          <FontAwesomeIcon icon={icon({ name: "power-off" })} /> Stop
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StopModal;
