import { useField } from "formik";
import * as React from "react";
import Select, { StylesConfig } from "react-select";

export interface DropdownOption {
  label: string;
  value: string;
}

type Props = {
  name: string;
  id: string;
  options: DropdownOption[];
  styles: StylesConfig<DropdownOption, false>;
};

const Dropdown = ({
  name,
  id,
  options,
  styles,
}: Props) => {
  const [field, , helpers] = useField(name);

  return (
    <Select
      id={id}
      styles={styles}
      value={options.find((option) => option.value === field.value)}
      options={options}
      isSearchable={false}
      onChange={(selectedOption, actionMeta) => {
        helpers.setValue(selectedOption?.value);
      }}
      onBlur={(e) => {
        helpers.setTouched(true);
        field.onBlur(e);
      }}
    />
  );
};

export default Dropdown;
