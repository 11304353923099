import React, { ReactNode } from "react";
import styled from "styled-components";

const Content = styled.div`
  grid-area: container;
  padding: 30px 15px;
  background-color: white;
  margin: 15px;
  margin-top: 50px;
`;

type Props = {
  code: number;
  message: string;
  children?: ReactNode;
};

export const ErrorPage = ({ code, message, children }: Props) => {
  return (
    <Content id="errorContent">
      {children}
      {code && <p className="text-muted">{code}</p>}
      {message && <p className="text-muted">{message}</p>}
      <a href="/">Click here to return to the Report page</a>
    </Content>
  );
};
