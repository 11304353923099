import {
  getSphericalDistanceInMeters,
  calculateVerticalAccuracy,
  dateFormatter,
  isCorrelatedControlPlane,
  isCorrelatedThunderbird,
} from "../../reportHelpers";
import { CorrelatedMlpLocation } from "../CorrelatedMlpLocation";
import CorrelationExport from "./CorrelationExport";
import MlpStatusOptions from "../MlpStatusOptions";

export default class CorrelationGroup {
  public mlpLocation: CorrelatedMlpLocation;

  constructor(
    mlpLocation: CorrelatedMlpLocation,
  ) {
    this.mlpLocation = mlpLocation;
  }

  public get accuracy() {
    if (
      !this.mlpLocation.correlatedGpsLocation ||
      (isCorrelatedControlPlane(this.mlpLocation) && !MlpStatusOptions.isSuccess(this.mlpLocation.statusValue)) ||
      (isCorrelatedThunderbird(this.mlpLocation) && this.mlpLocation.latitude === 0 && this.mlpLocation.longitude === 0)
    ) {
      return null;
    } else {
      const accuracy = getSphericalDistanceInMeters(
        this.mlpLocation.longitude,
        this.mlpLocation.latitude,
        this.mlpLocation.correlatedGpsLocation.longitude,
        this.mlpLocation.correlatedGpsLocation.latitude
      );

      return accuracy;
    }
  }

  public get isCorrelated() {
    return this.mlpLocation?.correlatedGpsLocation;
  }

  public get isYieldExlude() {
    if (isCorrelatedControlPlane(this.mlpLocation)) {
      return this.mlpLocation.yieldExclude;
    }

    return false;
  }

  public get status() {
    if (isCorrelatedControlPlane(this.mlpLocation)) {
      return this.mlpLocation.statusValue;
    } else {
      return this.mlpLocation?.longitude !== 0 || this.mlpLocation?.latitude !== 0
        ? MlpStatusOptions.STATUS_SUCCESS
        : MlpStatusOptions.STATUS_UNKNOWN;
    }
  }

  public get positionMethod() {
    return this.mlpLocation.positionMethod;
  }

  public get altitude() {
    return this.mlpLocation.altitude;
  }

  public get elevation() {
    return this.mlpLocation.correlatedGpsLocation?.elevation;
  }

  public get altitudeAccuracy() {
    if (this.altitude != null && this.elevation != null) {
      return calculateVerticalAccuracy(this.altitude, this.elevation);
    }

    return null;
  }

  public get hasUBP() {
    return isCorrelatedControlPlane(this.mlpLocation) && this.mlpLocation.ubp !== undefined
      ? true
      : false;
  }

  public toExport = (): CorrelationExport => {
    const result = new CorrelationExport();
    const e = this.mlpLocation.correlatedEvent;
    const mlp = this.mlpLocation;
    const gps = this.mlpLocation.correlatedGpsLocation;

    result.callId = e.callId;
    result.transactionId = e.transactionId;
    result.msisdn = e.msisdn;
    result.imsi = e.imsi;
    result.imei = e.imei;
    result.ranTech = e.radioAccessNetwork;
    result.isiOS = e.isiOS.toString().toUpperCase();
    result.callStartTime = dateFormatter(e.callStartTime);

    result.active = e.active.toString().toUpperCase();
    result.accuracy = this.accuracy;
    result.vertical_accuracy = this.altitudeAccuracy;
    
    if (isCorrelatedControlPlane(mlp)) {
      result.type = mlp.type;
      result.ascertained = dateFormatter(mlp.ascertainedTime);
      result.longitude = mlp.longitude;
      result.latitude = mlp.latitude;
      result.uncertainty = mlp.uncertainty;
      result.confidence = mlp.confidence;
      result.latency = mlp.latency;
      result.positionMethod = mlp.positionMethod;
      result.altitude = mlp.altitude;
      result.altitudeUncertainty = mlp.altitudeUncertainty;
      result.status_val = mlp.statusValue;
      result.status_str = mlp.statusName;
      result.isSuccessful = mlp.isSuccessful.toString().toUpperCase();
      result.cgi = mlp.cgi;
      result.ubp = mlp.ubp;
      result.neadAddressType = mlp.neadAddressType;
      result.neadCounty = mlp.neadCounty;
      result.neadHouseNumber = mlp.neadHouseNumber;
      result.neadFloor = mlp.neadFloor;
      result.neadUnit = mlp.neadUnit;
      result.neadRoad = mlp.neadRoad;
      result.neadCity = mlp.neadCity;
      result.neadState = mlp.neadState;
      result.neadPostalCode = mlp.neadPostalCode;
      result.locationRequestTime = mlp.locationRequestTime;
    } else if (isCorrelatedThunderbird(mlp)) {
      result.type = mlp.type;
      result.ascertained = dateFormatter(mlp.ascertainedTime);
      result.longitude = mlp.longitude;
      result.latitude = mlp.latitude;
      result.uncertainty = mlp.uncertainty;
      result.confidence = mlp.confidence;
      result.latency = mlp.latency;
      result.positionMethod = mlp.positionMethod;
      result.altitude = mlp.altitude;
      result.altitudeUncertainty = mlp.altitudeUncertainty;
      result.isSuccessful = mlp.isSuccessful.toString().toUpperCase();
      result.floorNumber = mlp.floorNumber;
    } else {
       //this will be SIP locations
      result.type = mlp.type;
      result.ascertained = dateFormatter(mlp.ascertainedTime);
      result.longitude = mlp.longitude;
      result.latitude = mlp.latitude;
      result.uncertainty = mlp.uncertainty;
      result.confidence = mlp.confidence;
      result.latency = mlp.latency;
      result.positionMethod = mlp.positionMethod;
      result.altitude = mlp.altitude;
      result.altitudeUncertainty = mlp.altitudeUncertainty;
      result.isSuccessful = mlp.isSuccessful.toString().toUpperCase();
    }

    if (gps !== null) {
      result.gpsUserName = gps.gpsUser;
      result.gpsLongitude = gps.longitude;
      result.gpsLatitude = gps.latitude;
      result.gpsAscertained = dateFormatter(gps.ascertainedTime);
      result.gpsStatic = gps.isStatic.toString().toUpperCase();
      result.gpsElevation = gps.elevation;
      result.gpsSpeed = gps.speed ? Number(gps.speed) : 0;
      result.gpsMorphology = gps.morphology;
      result.gpsHdop = gps.hdop ? Number(gps.hdop) : 0;
      result.gpsPdop = gps.pdop ? Number(gps.pdop) : 0;
      result.gpsVdop = gps.vdop ? Number(gps.vdop) : 0;
    }

    result.usedInReport = mlp.usedInReport.toString().toUpperCase();
    result.selectedVertical = mlp.selectedVertical.toString().toUpperCase();
    result.isVersionSupported = e.isVersionSupported.toString().toUpperCase();

    return result;
  };

}
