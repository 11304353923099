import React, { ReactNode, MouseEvent } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Col, Nav, Row, Tab } from "react-bootstrap";
import "./BatchTab.scss";
import DeleteButton from "../shared/DeleteButton";
import styled from "styled-components";

type Props<TData> = {
  tabs: TabConfig<TData>[];
  onTabClick: (key: string) => void;
  onAdd: (e: MouseEvent) => void;
  onDelete: (tab: TabConfig<TData>, index: number) => void;
  render: (tab: TabConfig<TData>, index: number) => ReactNode;
};

export type TabConfig<TData> = {
  title: string;
  key: string;
  id?: string;
  data: TData;
  hasError: boolean;
  isActive: boolean;
};

const BatchNavLink = styled(Nav.Link)`
  flex: 1;
  display: inline;
  
  color: #000000;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  padding: 0;
  padding-right: 15px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 10px;
  margin-left: 0px;
`;

const BatchSelectorCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
  margin-right: -2px;
`;

const BatchContentCol = styled(Col)`
  border: 1px;
  border-style: solid;
  border-color: #dfdfdf;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-bottom: 10px;
  padding-right: 10px;
`;

const AddBatch = styled.div`
  display: flex;
  justify-content: flex-end
`;

const AddBatchNavItem = styled(Nav.Item)`
  border: 0;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 1px;
  border-style: solid;
  border-color: #dfdfdf;
  background-color: #ebebeb;
  cursor: pointer;
  padding: 0.6em 1.1em;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const BatchTab = <TData extends object>({
  tabs,
  onTabClick,
  onAdd,
  onDelete,
  render,
}: Props<TData>) => {

  const handleAddClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onAdd(e);
  };

  const handleDeleteClick = (e: MouseEvent, tab: TabConfig<TData>, index: number) => {
    e.stopPropagation(); // VERY IMPORTANT!
    e.preventDefault();
    onDelete(tab, index);
  };

  const handleTabClick = (e: string | null) => {
    if (e !== null) {
      onTabClick(e);
    }
  };

  let activeTab: string = tabs.find((t) => t.isActive)?.key || tabs[0].key;

  return (
    <div className="BatchTabs">
      <Tab.Container
        id="batch-tab-container"
        activeKey={activeTab}
        onSelect={(e) => handleTabClick(e)}
      >
        {tabs && tabs.length > 0 && (
          <StyledRow id="batch-tab-row">
            <BatchSelectorCol id="batch-tab-col" sm={1}>
              {tabs.map((tab, i) => (
                <Nav className={classNames("BatchTabNav", {
                  active: tab.key === activeTab,
                  hasError: tab.hasError
                })}
                  id={`batch-tab-nav-${tab.key}`}
                  key={`batch-tab-nav-${tab.key}`}
                >
                  <Nav.Item className={classNames("BatchTab", {
                    active: tab.key === activeTab,
                    hasError: tab.hasError
                  })} >
                    <BatchNavLink eventKey={tab.key} className="text-nowrap">{tab.title}</BatchNavLink>
                    {tabs.length > 1 && (
                      <DeleteButton style={{ flex: "0" }} 
                        onClick={(e) => handleDeleteClick(e, tab, i)}
                      />
                    )}
                  </Nav.Item>
                </Nav>
              ))}

              <AddBatch id="addBatchContainer">
                <AddBatchNavItem id="addBatch" onClick={(e: MouseEvent<HTMLButtonElement>) => handleAddClick(e)}>
                  <FontAwesomeIcon icon={icon({ name: "plus" })} />
                </AddBatchNavItem>
              </AddBatch>
            </BatchSelectorCol>

            <BatchContentCol id="batch-col-content" sm={11}>
              <Tab.Content id="batch-content">
                {tabs.map((tab, i) => (
                  <Tab.Pane
                    className={classNames("tab-pane", {
                      active: tab.key === activeTab,
                    })}
                    key={tab.key}
                    eventKey={tab.key}
                  >
                    {render(tab, i)}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </BatchContentCol>
          </StyledRow>
        )}
      </Tab.Container>
    </div>
  );
};

export default BatchTab;