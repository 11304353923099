import { makeDeleteRequest, makeGetRequest, makePatchRequest, makePostRequest, makePutRequest } from "./apiService";
import ReportDefinition from "./models/ReportDefinitions/ReportDefinition";
import ReportDefinitionPatchRequest from "./models/ReportDefinitions/ReportDefinitionPatchRequest";
import ReportDefinitionSummary from "./models/ReportDefinitions/ReportDefinitionSummary";

const URL_BASE = "/api/report-definitions";

export const getReportDefinitionSummaries = () => makeGetRequest<ReportDefinitionSummary[]>(`${URL_BASE}/summary`);

export const updateReportDefinitionName = (id: number, name: string) =>
  makePatchRequest<ReportDefinition, ReportDefinitionPatchRequest>(`${URL_BASE}/${id}`, { name });

export const deleteReportDefinition = (id: number) => makeDeleteRequest<ReportDefinition>(`${URL_BASE}/${id}`);

export const getReportDefinition = (id: number) => makeGetRequest<ReportDefinition>(`${URL_BASE}/${id}`);

export const saveReportDefinition = (reportDefinition: ReportDefinition) =>
  makePostRequest<ReportDefinition, ReportDefinition>(URL_BASE, reportDefinition);

export const updateReportDefinition = (reportDefinition: ReportDefinition) =>
  makePutRequest<ReportDefinition, ReportDefinition>(`${URL_BASE}/${reportDefinition.id}`, reportDefinition);
