import styled from "styled-components";

const SiteLayout = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: 100%;
  background-color: lightgrey;
  grid-template:
    "header" 50px
    "container" auto
    "footer" 30px;
`;

export default SiteLayout;
