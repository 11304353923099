import { DateTime } from "luxon";
import { ESmlcEvent } from "../../../../../services/models/ESmlcEvent";
import { ESmlcLocation } from "../../../../../services/models/ESmlcLocation";
import { getSphericalDistanceInMeters, calculateVerticalAccuracy } from "../../reportHelpers";
import { GpsLocation } from "../GpsLocation";
import ESmlcCallExport from "./ESmlcCallExport";
import ESmlcLocationExport from "./ESmlcLocationExport";

export const eSmlcCallsReportHeader = [
  "CallId",
  "MSISDN",
  "ReferenceId",
  "PosRequestTime",
  "PosResponseTime",
  "RAN Tech",
  "CID",
  "Client type",
  "IMSI",
  "IMEI",
  "Requested Haccuracy",
  "Requested ResponseTime",
  "AGPScapability",
  "LPPsupport",
  "RxTXTDSupport",
  "GPSStandalone",
  "DBHSupport",
  "WLANSupport",
  "UBPsupport",
  "ResponseDataType",
  "UBP",
  "CA_Address",
  "CA_Method",
  "CA_Latitude",
  "CA_Longitude",
  "Country",
  "A1",
  "A2",
  "A3",
  "RD",
  "HNO",
  "PC",
  "CA_Token",
  "DBHcaError",
  "GNSScaError",
];

export const eSmlcLocationsReportHeader = [
  "CallId",
  "MSISDN",
  "LocationRet",
  "PositionMethod",
  "SourceAltitude",
  "ResultCode",
  "TimeStamp",
  "Shape",
  "HorizontalAccuracy",
  "VerticalAccuracy",
  "Long",
  "Lat",
  "UncertaintySMajor",
  "UncertaintySMinor",
  "Uncertainty",
  "Altitude",
  "UncertaintyAltitude",
  "Confidence",
  "Vconfidence",
  "HA3DLat",
  "HA3DLong",
  "HA3DUncertaintySMajor",
  "HA3DUncertaintySMinor",
  "HA3DUncertainty",
  "HA3DConfidence",
  "HA3DAltitude",
  "HA3DUncertaintyAltitude",
  "HA3DVconfidence",
  "LocationSource",
  "GPSLong",
  "GPSLat",
  "GPSElevation",
  "GPSAscertainedTime",
];

export const toCallExport = (event: ESmlcEvent): ESmlcCallExport => {
  const result = new ESmlcCallExport();
  
  result.callId = event.callId;
  result.msisdn = event.msisdn;
  result.referenceId = event.referenceId;
  result.posRequestTime = event.posRequestTime;
  result.posResponseTime = event.posResponseTime;
  result.radioAccessNetwork = event.radioAccessNetwork;
  result.cid = event.cid;
  result.clientType = event.clientType;
  result.imsi = event.imsi;
  result.imei = event.imei;
  result.requestedHorizontalAccuracy = event.requestedHorizontalAccuracy;
  result.requestedResponseTime = event.requestedResponseTime;
  result.agpScapability = event.agpScapability;
  result.lpPsupport = event.lpPsupport;
  result.rxTXTDSupport = event.rxTXTDSupport;
  result.gpsStandalone = event.gpsStandalone;
  result.dbhSupport = event.dbhSupport;
  result.wlanSupport = event.wlanSupport;
  result.ubpsupport = event.ubPsupport;
  result.responseDataType = event.responseDataType;
  result.ubp = event.ubp;
  result.ca_Address = event.cA_Address;
  result.ca_Method = event.cA_Method;
  result.ca_Latitude = event.cA_Latitude;
  result.ca_Longitude = event.cA_Longitude;
  result.country = event.country;
  result.a1 = event.a1;
  result.a2 = event.a2;
  result.a3 = event.a3;
  result.rd = event.rd;
  result.hno = event.hno;
  result.pc = event.pc;
  result.ca_Token = event.cA_Token;
  result.dbhCaError = event.dbhCaError;
  result.gnssCaError = event.gnssCaError;

  return result;
};

export const toLocationExport = (location: ESmlcLocation, gpsRecord: GpsLocation | null): ESmlcLocationExport => {
  const result = new ESmlcLocationExport();

  result.callId = location.callId;
  result.msisdn = location.msisdn;
  result.locationReturned = location.locationReturned;
  result.positionMethod = location.positionMethod;
  result.sourceAltitude = location.sourceAltitude;
  result.resultCode = location.resultCode;
  result.timeStamp = location.timeStamp;
  result.shape = location.shape;
  result.horizontalAccuracy = location.horizontalAccuracy;
  result.verticalAccuracy = location.verticalAccuracy;
  result.longitude = location.longitude;
  result.latitude = location.latitude;
  result.uncertaintySMajor = location.uncertaintySMajor;
  result.uncertaintySMinor = location.uncertaintySMinor;
  result.uncertainty = location.uncertainty;
  result.altitude = location.altitude;
  result.uncertaintyAltitude = location.uncertaintyAltitude;
  result.confidence = location.confidence;
  result.verticalConfidence = location.verticalConfidence;
  result.ha3DLat = location.hA3DLat;
  result.ha3DLong = location.hA3DLong;
  result.ha3DUncertaintySMajor = location.hA3DUncertaintySMajor;
  result.ha3DUncertaintySMinor = location.hA3DUncertaintySMinor;
  result.ha3DUncertainty = location.hA3DUncertainty;
  result.ha3DConfidence = location.hA3DConfidence;
  result.ha3DAltitude = location.hA3DAltitude;
  result.ha3DUncertaintyAltitude = location.hA3DUncertaintyAltitude;
  result.ha3DVerticalConfidence = location.hA3DVerticalConfidence;
  result.locationSource = location.locationSource;
  if (gpsRecord) {
    result.gpsLongitude = gpsRecord.longitude;
    result.gpsLatitude = gpsRecord.latitude;
    result.gpsElevation = gpsRecord.elevation;
    result.gpsAscertainedTime = DateTime.fromMillis(gpsRecord.ascertained, { zone: 'utc' }).toFormat("M/d/yyyy HH:mm:ss");
  }

  return result;
};

export const enum ErrorAccuracyType {
  DBH = "dbh",
  GNSS = "gnss",
};

export const calculateErrorAccuracy = (event: ESmlcEvent, errorAccuracyType: ErrorAccuracyType) => {
  let longitude: number | null = null;
  let latitude: number | null = null;

  switch (errorAccuracyType) {
    case ErrorAccuracyType.DBH: {
      const locationWithHighAccuracyData = event.eSmlcLocations.find(
        l => (l.hA3DLat !== 0 && l.hA3DLat !== null) && (l.hA3DLong !== 0 && l.hA3DLong !== null)
      );

      if (locationWithHighAccuracyData) {
        longitude = locationWithHighAccuracyData.hA3DLong;
        latitude = locationWithHighAccuracyData.hA3DLat;
      }
      break;
    }
    case ErrorAccuracyType.GNSS: {
      //Accuracy value in meters: spherical distance between CA LL and agnss_msb OR hybrid LL
      //there is only supposed to be one of these position methods.Use the first one that we find

      const positionMethodAgnssMsb = event.eSmlcLocations
        .find(p => p.positionMethod === "agnss-msb"
          && (p.resultCode === "0" || p.resultCode === "1")
          && (p.latitude !== null && p.latitude !== 0)
          && (p.longitude !== null && p.longitude !== 0)
        );

      if (positionMethodAgnssMsb) {
        longitude = positionMethodAgnssMsb.longitude;
        latitude = positionMethodAgnssMsb.latitude;
      }
      else {
        const positionMethodHybrid = event.eSmlcLocations
          .find(p => p.positionMethod === "hybrid"
            && (p.resultCode === "0" || p.resultCode === "1")
            && (p.latitude !== null && p.latitude !== 0)
            && (p.longitude !== null && p.longitude !== 0)
          );

        if (positionMethodHybrid) {
          longitude = positionMethodHybrid.longitude;
          latitude = positionMethodHybrid.latitude;
        }
      }

      break;
    }

    default:
      throw new Error(`Invalid error accuracy type ${errorAccuracyType}.`);
  }

  const validLatValues = event.cA_Latitude !== null && latitude !== null && event.cA_Latitude !== 0 && latitude !== 0;
  const validLongValues = event.cA_Longitude !== null && longitude !== null && event.cA_Longitude !== 0 && longitude !== 0;

  if (!validLatValues && !validLongValues) {
    return null;
  } else {
    const accuracy = getSphericalDistanceInMeters(
      event.cA_Longitude,
      event.cA_Latitude,
      Number(longitude),
      Number(latitude)
    );

    return accuracy;
  }
};

export const getVerticalAccuracy = (altitude: number | null, elevation: number | null) => {
  if (altitude != null && elevation != null) {
    return calculateVerticalAccuracy(altitude, elevation);
  }

  return null;
};

export const getHorizontalAccuracy = (
  eSmlcLongitude: number | null,
  eSmlcLatitude: number | null,
  gpsLongitude: number | null,
  gpsLatitude: number | null
) => {
  if (eSmlcLatitude === null || eSmlcLongitude === null ||
    gpsLongitude === null || gpsLatitude === null ||
    (eSmlcLatitude === 0 && eSmlcLongitude === 0)) {
    return null;
  } else {
    const accuracy = getSphericalDistanceInMeters(
      eSmlcLongitude,
      eSmlcLatitude,
      gpsLongitude,
      gpsLatitude
    );

    return accuracy;
  }
};