import { IconProp, RotateProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonHTMLAttributes, MouseEvent } from "react";
import styled from "styled-components";

type StyledButtonProps = {
  $hoverColor: string;
  $isVisible: boolean;
};
const StyledButton = styled.button<StyledButtonProps>`
  visibility: ${(props) => (props.$isVisible ? "visible" : "hidden")};
  color: gray;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover:not(:disabled) {
    text-decoration: underline;
    color: ${(props) => props.$hoverColor};
  }

  &:disabled {
    cursor: default;
    color: rgba(80, 80, 80, 0.5);
  }
`;

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: (e: MouseEvent<HTMLButtonElement>, props: any) => void;
  title: string;
  iconProp: IconProp;
  hoverColor: string;
  rotation?: RotateProp;
  disabled?: boolean;
  isVisible?: boolean;
};

const IconButton = ({
  onClick,
  title,
  iconProp,
  hoverColor,
  rotation,
  disabled = false,
  isVisible = true,
  ...props
}: Props) => {
  return (
    <span title={title} data-toggle="tooltip" data-placement="bottom">
      <StyledButton
        $isVisible={isVisible}
        $hoverColor={hoverColor}
        onClick={(e: MouseEvent<HTMLButtonElement>) => onClick(e, props)}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={iconProp} rotation={rotation} />
      </StyledButton>
    </span>
  );
};

export default IconButton;
