import { ValueFormatterFunc, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { validTimeZones } from "../../../utilities/constants";
import {
  TimeZoneGridDisplay,
  TimeZoneValue,
  getTimeZoneIdentifierFromAbbreviation,
} from "../../../utilities/timezone";
import { formatDateTimeUtc, formatMsisdn } from "../shared/formatters";
import { DateTime, Duration } from "luxon";
import { MtlrSetWithDisplayName } from "../../../services/models/MtlrSetWithDisplayName";

// format will be 04/03/2021 8:02 AM
export const dateFormatter: ValueFormatterFunc = (params: ValueFormatterParams) => {
  const data: MtlrSetWithDisplayName = params.data;
  const timeZoneIdentifier = getTimeZoneIdentifierFromAbbreviation(data.timeZone);

  return formatDateTimeUtc(params.value, timeZoneIdentifier);
};

export const userFormatter = (value: string) => {
  return value ? value : "Unknown";
};

//format 111-222-3333
export const msisdnFormatter = (value: string) => {
  if (value) {
    return formatMsisdn(value);
  }

  return value;
};

export enum StatusStates {
  Running = "Running",
  Future = "Future",
  Completed = "Completed",
  NotStarted = "Not Started",
  Paused = "Paused",
}

export const statusGetter = (params: any) => {
  const startTimeUtc = params.data.startTimeUtc === null
    ? params.data.startTimeUtc
    : DateTime.fromISO(params.data.startTimeUtc.toString(), { zone: "utc" });

  const endTimeUtc = params.data.endTimeUtc === null
    ? params.data.endTimeUtc
    : DateTime.fromISO(params.data.endTimeUtc.toString(), { zone: "utc" });

  const now = DateTime.now().setZone("utc");

  if (params.data.isScheduled) {
    if (now > endTimeUtc) {
      return StatusStates.Completed;
    } else if (startTimeUtc <= now && now <= endTimeUtc && params.data.isPaused === true) {
      return StatusStates.Paused;
    } else if (startTimeUtc <= now && now <= endTimeUtc) {
      return StatusStates.Running;
    } else if (startTimeUtc >= now && now <= endTimeUtc) {
      return StatusStates.Future;
    }
  } else {
    if (startTimeUtc === null && endTimeUtc === null) {
      return StatusStates.NotStarted;
    } else if (startTimeUtc <= now && endTimeUtc === null && params.data.isPaused === false) {
      return StatusStates.Running;
    } else if (startTimeUtc <= now && endTimeUtc === null && params.data.isPaused === true) {
      return StatusStates.Paused;
    } else if (startTimeUtc <= now && now >= endTimeUtc && params.data.isPaused === false) {
      return StatusStates.Completed;
    }
  }

  return StatusStates.NotStarted;
};

export const intervalFormatter = (value: number) => {
  return formatIntervalToTime(value);
};

export const formatIntervalToTime = (intervalSeconds: number) => {
  if (intervalSeconds) {
    return Duration.fromObject({ seconds: intervalSeconds }).toFormat("hh:mm:ss"); // => '00:00:30'
  }

  return "";
};

export const timeZoneFormatter = ({ value }: { value: TimeZoneValue; }) => {
  return formatTimeZoneAbbreviationToGridDisplay(value);
};

export const formatTimeZoneAbbreviationToGridDisplay = (
  timeZoneAbbreviation: TimeZoneValue
): TimeZoneGridDisplay => {
  switch (timeZoneAbbreviation) {
    case validTimeZones.alaska.value:
      return validTimeZones.alaska.gridDisplay;
    case validTimeZones.arizona.value:
      return validTimeZones.arizona.gridDisplay;
    case validTimeZones.central.value:
      return validTimeZones.central.gridDisplay;
    case validTimeZones.eastern.value:
      return validTimeZones.eastern.gridDisplay;
    case validTimeZones.hawaii.value:
      return validTimeZones.hawaii.gridDisplay;
    case validTimeZones.mountain.value:
      return validTimeZones.mountain.gridDisplay;
    case validTimeZones.pacific.value:
      return validTimeZones.pacific.gridDisplay;
    case validTimeZones.utc.value:
      return validTimeZones.utc.gridDisplay;
    default:
      throw new Error("Invalid time zone value.");
  }
};


export const countGetter = (params: ValueGetterParams, count: number | null): number | null => {
  const statusState = statusGetter(params);

  return count ? count : (statusState !== StatusStates.NotStarted) && (statusState !== StatusStates.Future) ? 0 : null
}