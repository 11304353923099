import { validTimeZones } from "./constants";

export type TimeZoneValue = (typeof validTimeZones)[keyof typeof validTimeZones]["value"];
export type TimeZoneIdentifier = (typeof validTimeZones)[keyof typeof validTimeZones]["identifier"];
export type TimeZoneGridDisplay =
  (typeof validTimeZones)[keyof typeof validTimeZones]["gridDisplay"];

/**
 * Converts an input timeZone identifier such as "America/Anchorage" to its abbreviation.
 * If it's not a valid US timezone identifier, it returns the Pacific timezone abbreviation "PT" by default
 * @param timeZoneIdentifier
 * @returns Timezone abbreviation
 */
export const getUserTimeZoneAbbreviationFromIdentifier = (
  timeZoneIdentifier: TimeZoneIdentifier
) => {
  switch (timeZoneIdentifier) {
    case validTimeZones.alaska.identifier:
      return validTimeZones.alaska.value;
    case validTimeZones.arizona.identifier:
      return validTimeZones.arizona.value;
    case validTimeZones.central.identifier:
      return validTimeZones.central.value;
    case validTimeZones.eastern.identifier:
      return validTimeZones.eastern.value;
    case validTimeZones.hawaii.identifier:
      return validTimeZones.hawaii.value;
    case validTimeZones.mountain.identifier:
      return validTimeZones.mountain.value;
    case validTimeZones.pacific.identifier:
      return validTimeZones.pacific.value;
    case validTimeZones.utc.identifier:
      return validTimeZones.utc.value;
    default:
      return validTimeZones.pacific.value;
  }
};

export const getTimeZoneIdentifierFromAbbreviation = (
  abbreviation: TimeZoneValue
): TimeZoneIdentifier => {
  switch (abbreviation) {
    case validTimeZones.alaska.value:
      return validTimeZones.alaska.identifier;
    case validTimeZones.arizona.value:
      return validTimeZones.arizona.identifier;
    case validTimeZones.central.value:
      return validTimeZones.central.identifier;
    case validTimeZones.eastern.value:
      return validTimeZones.eastern.identifier;
    case validTimeZones.hawaii.value:
      return validTimeZones.hawaii.identifier;
    case validTimeZones.mountain.value:
      return validTimeZones.mountain.identifier;
    case validTimeZones.pacific.value:
      return validTimeZones.pacific.identifier;
    case validTimeZones.utc.value:
      return validTimeZones.utc.identifier;
    default:
      throw new Error("Invalid time zone abbreviation.");
  }
};

export const getValidUSTimeZoneIdentifier = (timeZoneIdentifier: string): TimeZoneIdentifier => {
  if (
    timeZoneIdentifier === validTimeZones.alaska.identifier ||
    timeZoneIdentifier === validTimeZones.arizona.identifier ||
    timeZoneIdentifier === validTimeZones.central.identifier ||
    timeZoneIdentifier === validTimeZones.eastern.identifier ||
    timeZoneIdentifier === validTimeZones.hawaii.identifier ||
    timeZoneIdentifier === validTimeZones.mountain.identifier ||
    timeZoneIdentifier === validTimeZones.pacific.identifier ||
    timeZoneIdentifier === validTimeZones.utc.identifier
  ) {
    return timeZoneIdentifier;
  } else {
    return validTimeZones.pacific.identifier;
  }
};

export const getTimeZoneGridDisplayFromAbbreviation = (
  abbreviation: TimeZoneValue
): TimeZoneGridDisplay => {
  switch (abbreviation) {
    case validTimeZones.alaska.value:
      return validTimeZones.alaska.gridDisplay;
    case validTimeZones.arizona.value:
      return validTimeZones.arizona.gridDisplay;
    case validTimeZones.central.value:
      return validTimeZones.central.gridDisplay;
    case validTimeZones.eastern.value:
      return validTimeZones.eastern.gridDisplay;
    case validTimeZones.hawaii.value:
      return validTimeZones.hawaii.gridDisplay;
    case validTimeZones.mountain.value:
      return validTimeZones.mountain.gridDisplay;
    case validTimeZones.pacific.value:
      return validTimeZones.pacific.gridDisplay;
    case validTimeZones.utc.value:
      return validTimeZones.utc.gridDisplay;
    default:
      throw new Error("Invalid time zone abbreviation.");
  }
};
