import classnames from "classnames";
import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.css";

type Props = ReactDatePickerProps & {
  value: Date;
  isInvalid?: boolean;
  className?: string;
};

const DateTimePicker = ({ value, isInvalid, className = "", ...props }: Props) => {
  return (
    <DatePicker
      selected={value}
      className={classnames("form-control", { "is-invalid": isInvalid }, className)}
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy h:mm aa"
      showTimeInput
      placeholderText="mm/dd/yyyy hh:mm"
      shouldCloseOnSelect={false}
      isClearable={true}
      clearButtonClassName="btn-close datepicker-close-button"
      {...props}
    />
  );
};

export default DateTimePicker;
