import { useFormikContext } from "formik";
import React from "react";
import { Form as BSForm, FormProps } from "react-bootstrap";

/**
 * This custom Form component combines the styling of a `react-bootstrap` Form
 * with the behavior of a Formik Form component.
 *
 * Note: This component MUST be the child of a <Formik/> component to behave
 * properly.
 *
 * Docs:
 * - https://react-bootstrap-v4.netlify.app/components/forms/
 * - https://formik.org/docs/api/form
 */
const Form = (props: FormProps) => {
  const { handleReset, handleSubmit } = useFormikContext();

  return (
    <BSForm
      className="mb-3"
      onReset={handleReset}
      onSubmit={handleSubmit}
      noValidate
      {...props}
    />
  );
};

export default Form;
