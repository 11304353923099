class ESmlcCallExport {
  public callId: number = -1;
  public msisdn: string | null = null;
  public referenceId: string | null = null;
  public posRequestTime: string | null = null;
  public posResponseTime: string | null = null;
  public radioAccessNetwork: string | null = null;
  public cid: string | null = null;
  public clientType: string | null = null;
  public imsi: string | null = null;
  public imei: string | null = null;
  public requestedHorizontalAccuracy: number | null = null;
  public requestedResponseTime: string | null = null;
  public agpScapability: string | null = null;
  public lpPsupport: string | null = null;
  public rxTXTDSupport: string | null = null;
  public gpsStandalone: string | null = null;
  public dbhSupport: string | null = null;
  public wlanSupport: string | null = null;
  public ubpsupport: string | null = null;
  public responseDataType: string | null = null;
  public ubp: string | null = null;
  public ca_Address: string | null = null;
  public ca_Method: string | null = null;
  public ca_Latitude: number | null = null;
  public ca_Longitude: number | null = null;
  public country: string | null = null;
  public a1: string | null = null;
  public a2: string | null = null;
  public a3: string | null = null;
  public rd: string | null = null;
  public hno: string | null = null;
  public pc: string | null = null;
  public ca_Token: string | null = null;
  public dbhCaError: number | null = null;
  public gnssCaError: number | null = null;
}

export default ESmlcCallExport;
