/* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token-outside-of-a-react-component */

import msalInstance from "./msalInstance";

export const acquireAccessToken = async (force: boolean = false) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }
  
  const request = {
    scopes: window.appSettings?.azureAdScopes,
    account: activeAccount || accounts[0],
    forceRefresh: force,
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
};
