export class AdvancedReportFilters {
  useRanFilters: boolean = false;
  ran2G: boolean = true;
  ran4G: boolean = true;
  ran5G: boolean = true;
  useCgiFilter: boolean = false;
  cgi1: string = "310";
  cgi2: string = "260";
  cgi3: string = "";
  cgi4: string = "";
  useGeographicFilter: boolean = false;
  latitude: string = "";
  longitude: string = "";
  radius: string = "";
  minAltitude: string = "";
  maxAltitude: string = "";
};
