import { useField } from "formik";
import React, { useState, useEffect, useMemo } from "react";
import Select, { StylesConfig } from "react-select";
import { AddBulkMsisdns } from "./AddBulkMsisdns";

export interface OptionType {
  readonly value: string;
  readonly label: string;
}

type Props = {
  id: string;
  name: string;
  options: OptionType[];
  placeholder: string;
  styles: StylesConfig<OptionType, true>;
};

const MsisdnPicker = ({ id, name, options, placeholder, styles }: Props) => {
  const [field, , helpers] = useField<string[]>(name);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const [typed, setTyped] = useState<string>();

  const sortedOptions = useMemo(
    () => [...options].sort((a, b) => parseFloat(a.value) - parseFloat(b.value)),
    [options]
  );

  useEffect(() => {
    if (field.value !== null && sortedOptions.length > 0) {
      const optionsToSelect = sortedOptions.filter((o) => field.value.includes(o.value));
      setSelectedOptions(optionsToSelect);
    }
  }, [field.value, sortedOptions]);

  const handleMsisdnsChanged = (options: OptionType[]) => {
    setSelectedOptions(options);
    helpers.setValue(options.map((v) => v.value));
  };

  return (
    <>
      <Select
        id={id}
        name={name}
        styles={styles}
        isMulti={true}
        menuIsOpen={true}
        placeholder={placeholder}
        value={selectedOptions}
        onChange={(selectedOption, actionMeta) => {
          setTyped(typed);

          //sort the items displayed in the multi-select value container
          const sorted = [...selectedOption].sort(
            (a, b) => parseFloat(a.value) - parseFloat(b.value)
          );
          handleMsisdnsChanged(sorted);
        }}
        onBlur={field.onBlur}
        options={sortedOptions}
        inputValue={typed}
        onInputChange={(input, actionMeta) => {
          const validCharacter = /^[-\d]*$/.test(input);
          const isReactSelectClearingTheInput = actionMeta.action === "set-value" && !input;
          const isInputChange = actionMeta.action === "input-change";

          if (!isReactSelectClearingTheInput && validCharacter) {
            setTyped(input);
          } else if (!validCharacter && isInputChange) {
            setTyped(actionMeta.prevInputValue);
          }
        }}
      />

      <AddBulkMsisdns
        allowListMsisdnOptions={options}
        selectedMsisdns={selectedOptions}
        setSelectedMsisdns={handleMsisdnsChanged}
      />
    </>
  );
};

export default MsisdnPicker;
