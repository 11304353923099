import React, { ReactNode } from "react";
import { useUserContext } from "../../contexts/UserContext";

export type AuthCheckProps = {
  allow?: () => ReactNode;
  deny?: () => ReactNode;
  requiredPermissions?: string[];
};

export const AuthCheck = ({
  allow = () => null,
  deny = () => null,
  requiredPermissions = [],
}: AuthCheckProps) => {
  const { permissions, isLoading } = useUserContext();

  if (isLoading) {
    // Still fetching permissions from the API, don't render anything yet.
    return null;
  }

  return permissions.browserPermissions.some((p) => requiredPermissions.includes(p)) ? (
    <>{allow()}</>
  ) : (
    <>{deny()}</>
  );
};
