import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICellRendererParams } from "ag-grid-community";
import React, { MouseEvent } from "react";
import styled from "styled-components";
import { useGmlcData } from "./GmlcDataProvider";
import { statusGetter, StatusStates } from "./mtlrHelpers";

const StyledButton = styled.button`
  color: gray;
  background: none;
  border: 0;
  padding: 0;
`;

const OpenImage = styled.div`
  color: #e20074;
  font-size: 18px;
`;

type Props = ICellRendererParams & {
  onClick: (e: MouseEvent<HTMLButtonElement>, props: any) => void;
};

const ExpandButton = ({ onClick, ...props }: Props) => {
  const { rowId, setRowId } = useGmlcData();
  const status = statusGetter(props);

  if (status !== undefined && status !== StatusStates.NotStarted && status !== StatusStates.Future) {
    return (
      <span title="Show GMLC data" data-toggle="tooltip" data-placement="bottom">
        <StyledButton
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            if (rowId === props.data.id) {
              setRowId(null);
              onClick(e, null);
            } else {
              setRowId(props.data.id);
              onClick(e, props);
            }
          }}
        >
          {rowId === props.data.id ? (
            <OpenImage><FontAwesomeIcon icon={icon({ name: "chevron-right" })} /></OpenImage>
          ) : (
            <FontAwesomeIcon icon={icon({ name: "chevron-right" })} />
          )}
        </StyledButton>
      </span>
    );
  } else {
    return null;
  }
};

export default ExpandButton;
