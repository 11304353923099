import EventSmallestValueList from "./EventSmallestValueList";

export default class ReportRecord {
  public positionMethod: string = "";
  public os: string = "";
  public reportableEventCount: number = 0;
  public reportableiOSEventCount: number = 0;
  public reportableAndroidEventCount: number = 0;

  public positionMethodCount: number = 0;

  public withinHorizontalAccuracyThresholdCount: number = 0;
  public horizontalAccuracyWithinUncertaintyCount: number = 0;
  public horizontalAccuracyPercentile1: number | null = null;
  public horizontalAccuracyPercentile2: number | null = null;
  public horizontalAccuracy: number | null = null;
  public horizontalLatency: number | null = null;
  public horizontalLatencyPercentile1: number | null = null;
  public horizontalLatencyPercentile2: number | null = null;
  public withAltitudeCount: number = 0;

  public verticalAccuracyWithinThresholdCount: number = 0;
  public withVerticalUncertaintyCount: number = 0;
  public verticalAccuracy: number | null = null;
  public verticalAccuracyPercentile1: number | null = null;
  public verticalAccuracyPercentile2: number | null = null;
  public verticalUncertaintyCount: number = 0;

  public horizontalUncertaintyValues: EventSmallestValueList | null = null;
  public verticalAccuracyValues: EventSmallestValueList | null = null;
  public verticalUncertaintyValues: EventSmallestValueList | null = null;

  public horizontalAccuracyVsUncertainty: number | null = null;
  public verticalAccuracyVsUncertainty: number | null = null;

  public ubpValues: EventSmallestValueList | null = null;
  public ubpCount: number = 0;
}