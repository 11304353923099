import { createContext } from "react";

type GmlcDataContextType = {
  rowId: number | null;
  setRowId: (arg: number | null) => void;
};

export const GmlcDataContext = createContext<GmlcDataContextType>({
  rowId: null,
  setRowId: (arg: number| null) => { },
});
