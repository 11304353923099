import { GpsLocation } from "./GpsLocation";
import { Event } from "../../../../services/models/Event";
import { DateTime } from "luxon";

export class CorrelatedMlpLocation  {
  longitude: number;
  latitude: number;
  ascertainedTime: string;
  truncatedAscertained_ms: number;
  latency: number;
  positionMethod: string;
  uncertainty?: number;
  altitude?: number;
  altitudeUncertainty?: number;
  confidence?: number;
  selectedVertical: boolean;
  type: string;
  eventCallId: number;
  isSuccessful: boolean;
  correlatedGpsLocation: GpsLocation | null;
  correlatedEvent: Omit<Event, "mlpLocations">;
  mlpId: number;
  totalMlps: number;
  usedInReport: boolean;

  constructor(
    longitude: number,
    latitude: number,
    ascertainedTime: string,
    roundedAscertained_ms: number,
    latency: number,
    selectedVertical: boolean,
    positionMethod: string,
    type: string,
    eventCallId: number,
    isSuccessful: boolean,
    correlatedGpsLocation: GpsLocation | null,
    correlatedEvent: Omit<Event, "mlpLocations">,
    mlpId: number,
    totalMlps: number,
    usedInReport: boolean,
    uncertainty?: number,
    altitude?: number,
    altitudeUncertainty?: number,
    confidence?: number,
  ) {
    this.ascertainedTime = ascertainedTime;
    this.truncatedAscertained_ms = roundedAscertained_ms;
    this.longitude = longitude;
    this.latitude = latitude;
    this.latency = latency;
    this.positionMethod = positionMethod;
    this.uncertainty = uncertainty;
    this.altitude = altitude;
    this.altitudeUncertainty = altitudeUncertainty;
    this.confidence = confidence;
    this.selectedVertical = selectedVertical;
    this.type = type;
    this.eventCallId = eventCallId;
    this.isSuccessful = isSuccessful;
    this.correlatedGpsLocation = correlatedGpsLocation;
    this.correlatedEvent = correlatedEvent;
    this.mlpId = mlpId;
    this.totalMlps = totalMlps;
    this.usedInReport = usedInReport;
  }

  static sort = (a: CorrelatedMlpLocation, b: CorrelatedMlpLocation): number => {
    return DateTime.fromISO(a.ascertainedTime, { zone: "utc" }).diff(DateTime.fromISO(b.ascertainedTime, { zone: "utc" })).toMillis();
  };
};
