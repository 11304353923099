import EventIdList from "./EventIdList";

export default class EventIdListPerPositionMethod {
  private _data = new Map<string, EventIdList>();

  public get positionMethods(): string[] {
    return [...this._data.keys()];
  }

  public add = (positionMethod: string, eventId: number): void => {
    if (!this._data.has(positionMethod)) {
      this._data.set(positionMethod, new EventIdList());
    }

    this._data.get(positionMethod)?.add(eventId);
  };

  public get = (positionMethod: string): EventIdList | null => {
    return this._data.get(positionMethod) || null;
  };
}
