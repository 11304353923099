export const MTLR_DATA_FOR_REPORT_SESSION_STORAGE = "mtlrDataForReport";

export const DateTimeOption = {
  LAST_FOUR_HOURS: "lastFourHours",
  LAST_EIGHT_HOURS: "lastEightHours",
  LAST_TWENTY_FOUR_HOURS: "lastTwentyFourHours",
  LAST_SEVEN_DAYS: "lastSevenDays",
  LAST_THIRTY_DAYS: "lastThirtyDays",
  CUSTOM_DATE_TIME: "customDateTime",
};

export const customMultiSelectStyles = (error: boolean) => {
  return {
    /* styles for input box for msisdn entry */
    control: (provided: any) => ({
      ...provided,
      border: error ? "1px solid red" : "",
    }),
    /* styles for menu dropdown list */
    menu: (provided: any) => ({
      ...provided,
      position: "relative",
      boxShadow: "none",
      border: "1px solid #dfdfdf",
      width: "250px",
    }),
    /* styles for menuList  */
    menuList: (provided: any) => ({
      ...provided,
      height: "240px",
    }),
    /* change the spacing of the items in the menulist */
    option: (provided: any) => ({
      ...provided,
      paddingTop: "1px",
      paddingBottom: "1px",
    }),
  };
};
