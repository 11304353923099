import { DateTime } from "luxon";
import { TimeZoneIdentifier } from "../../../utilities/timezone";

//format 111-222-3333
export const formatMsisdn = (value: string) => {
  if (value) {
    return `${value.substring(0, 3)}-${value.substring(3, 6)}-${value.substring(6, 10)}`;
  }

  return value;
};

export const formatDateTimeUtc = (
  value: string,
  timeZoneIdentifier: TimeZoneIdentifier = "America/Los_Angeles",
  includeMinutes: boolean = true
) => {
  if (value) {
    const dateUtc = DateTime.fromISO(value, { zone: "utc" }).setZone(timeZoneIdentifier);

    const datePart = dateUtc.toLocaleString({
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    const timePart = dateUtc.toLocaleString({
      hour: "numeric",
      minute: includeMinutes ? "numeric" : undefined,
    });

    return `${datePart} ${timePart}`;
  }

  return value;
};

export const numberFormatter = (value: number) => {
  const parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
