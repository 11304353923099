import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
  show: boolean;
  reportEligible: number;
  reportIneligible: number;
  onModalReport: (event: React.SyntheticEvent) => void;
  onModalClose?: () => void;
};

const title = (reportEligible: number) => {
  if (reportEligible === 0) {
    return ("The selected MTLR Set(s) are not Report ready");
  }

  return ("Some selected MTLR Set(s) are not Report ready. Generate a Report for ready MTLR Sets only?");
};

const body = (reportEligible: number, reportIneligible: number) => {
  if (reportEligible === 0) {
    return ("You must select at least 1 Report ready MTLR Set");
  }

  return (
    <>
      <div>Ready MTLR Sets: {reportEligible}</div>
      <div className="mt-2">Not Ready MTLR Sets: {reportIneligible}</div>
    </>
  );
};

const ReportModal = ({ show, reportEligible, reportIneligible, onModalReport, onModalClose }: Props) => {
  return (
    <Modal show={show} onHide={onModalClose}>
      <Modal.Header>
        <Modal.Title>{title(reportEligible)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body(reportEligible, reportIneligible)}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button size="sm" variant="outline-secondary" onClick={onModalClose}>
          <FontAwesomeIcon icon={icon({ name: "level-up-alt" })} className="fa-rotate-270 me-1" />{" "}
          Cancel
        </Button>
        <Button size="sm" variant="primary" onClick={onModalReport} disabled={reportEligible === 0}>
          <FontAwesomeIcon icon={icon({ name: "file-lines" })} /> Report
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportModal;
