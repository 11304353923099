import { DateTime } from "luxon";
import { validTimeZones } from "./constants";
import { TimeZoneIdentifier } from "./timezone";

export const getLocalTimeAsUTC = (
  timeZoneIdentifier: TimeZoneIdentifier = validTimeZones.pacific.identifier
): DateTime => {
  const localTime = DateTime.now().setZone(timeZoneIdentifier, {
    keepLocalTime: true,
  });

  const utc = localTime.toUTC();

  return utc;
};

export const getTimeZoneSpecificTimeAsLocalTime = (timeZoneIdentifier: TimeZoneIdentifier) => {
  const timeZoneSpecificTime = DateTime.now().setZone(timeZoneIdentifier);

  const localTime = timeZoneSpecificTime.setZone("local", {
    keepLocalTime: true,
  });

  return localTime;
};

export const localDateToUTCDateTime = (local: Date, timeZoneIdentifier: TimeZoneIdentifier) => {
  const localTime = DateTime.fromJSDate(local);
  const timeZoneSpecificTime = localTime.setZone(timeZoneIdentifier, {
    keepLocalTime: true,
  });

  const utc = timeZoneSpecificTime.toUTC();

  return utc;
};

export const getPacificTimeAsLocalTime = () => {
  const localPacificTime = DateTime.now().setZone("America/Los_Angeles");

  const localTime = localPacificTime.setZone("local", {
    keepLocalTime: true,
  });

  return localTime;
};

/**
 * Convert a UTC ISO string to a JSDate object representing the date and time of the specified time zone.
 * @param utcISO A datetime ISO string in the UTC zone.
 * @param timeZoneIdentifier The time zone represented by the date and time in the result.
 * @returns A JS Date object representing the provided UTC ISO string converted to the specified time zone.
 */
export const utcISOToJsDateInTimeZone = (utcISO: string, timeZoneIdentifier: TimeZoneIdentifier): Date => {
  return DateTime.fromISO(utcISO, { zone: "utc" })
    .setZone(timeZoneIdentifier)
    .setZone("local", { keepLocalTime: true })
    .toJSDate();
};
