import styled from "styled-components";

const Footer = styled.footer`
  grid-area: footer;
  display: flex;
  font-size: x-small;
  padding-left: 15px;
  opacity: 0.5;
`;

export default Footer;
