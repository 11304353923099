import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React from "react";
import Clock from "react-live-clock";
import styled from "styled-components";
import logo from "../../images/tmobile_logo.png";
import { SignOutButton } from "../auth/SignOutButton";
import { LeftSection, RightSection } from "./HeaderSection";
import { useUserContext } from "../../contexts/UserContext";
import { validTimeZones } from "../../utilities/constants";

const StyledHeader = styled.header`
  grid-area: header;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-areas:
    "left right"
    ". right";
  padding-left: 20px;
  padding-right: 20px;
`;

const Image = styled.img`
  height: 2.5rem;
  margin-right: 0.5rem;
`;

const H1 = styled.h1`
  font-weight: lighter;
  white-space: nowrap;
  font-size: x-large;
  margin-top: 18px;
`;

const Username = styled.span`
  margin-right: 0.5rem;
`;

const RightSectionDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 17px;
`;

const getTimeZoneName = (timeZone: string) => {
  if (timeZone === validTimeZones.arizona.identifier) {
    return "Arizona";
  }

  let timeZoneName = new Intl.DateTimeFormat("en", {
    timeZone: timeZone,
    timeZoneName: "long",
  })
    .formatToParts()
    .find((part) => part.type === "timeZoneName")
    ?.value.replace("Standard", "");

  if (timeZoneName === "Coordinated Universal Time") {
    timeZoneName = "UTC";
  }

  return timeZoneName;
};

const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const { userTimeZoneIdentifier } = useUserContext();
  const timeZoneName = getTimeZoneName(userTimeZoneIdentifier);

  return (
    <StyledHeader>
      <LeftSection>
        <Image src={logo} alt="t-mobile logo" />
        <H1>LCAT - Location Accuracy Test</H1>
      </LeftSection>

      <RightSection>
        {isAuthenticated && (
          <>
            <RightSectionDiv>
              <Username>{accounts[0].username}</Username>
              <SignOutButton />
            </RightSectionDiv>
          </>
        )}
        <span className="medium text-nowrap">
          <Clock
            format="MMMM Do, YYYY h:mm:ss A"
            ticking={true}
            timezone={userTimeZoneIdentifier}
          />{" "}
          ({timeZoneName})
        </span>
      </RightSection>
    </StyledHeader>
  );
};

export default Header;
