export type SortDirection = "asc" | "desc";

export default abstract class Sort {
  static alphabeticalSort = (a?: string, b?: string, dir: SortDirection = "asc") => {
    const dirMod = dir.toLowerCase() === "desc" ? -1 : 1;

    let termA = a?.toString().trim();
    let termB = b?.toString().trim();

    if (!termA && !termB) {
      return 0;
    } else if (!termA) {
      return 1 * dirMod;
    } else if (!termB) {
      return -1 * dirMod;
    }

    return termA.toLowerCase().localeCompare(termB.toLowerCase()) * dirMod;
  };
}
