import { DateTime } from "luxon";
import { isGpsLoggerHeader } from "../reportHelpers";

export class GpsLocation {
  gpsUser: string | null; // userId
  msisdn: string;
  msisdnName: string | null;
  ascertained: number;
  ascertainedTime: string;
  truncatedAscertained_ms: number;
  longitude: number;
  latitude: number;
  elevation: number;
  speed: string | null;
  satelliteCount: string | null;

  status: string | null;
  morphology: string | null;

  hdop: string | null;
  pdop: string | null;
  vdop: string | null;

  isStatic: boolean;

  constructor(
    gpsUser: string | null,
    msisdn: string,
    msisdnName: string | null,
    ascertained: number,
    ascertainedTime: string,
    longitude: number,
    latitude: number,
    elevation: number,
    speed: string | null,
    satelliteCount: string | null,
    status: string | null,
    morphology: string | null,
    hdop: string | null,
    pdop: string | null,
    vdop: string | null,
    isStatic: boolean,
  ) {
    this.msisdn = msisdn;
    this.msisdnName = msisdnName;
    this.gpsUser = gpsUser;
    this.ascertained = ascertained;
    this.ascertainedTime = ascertainedTime;
    this.truncatedAscertained_ms = ascertained ? ascertained : 0;
    this.longitude = longitude;
    this.latitude = latitude;
    this.elevation = elevation;
    this.speed = speed;
    this.satelliteCount = satelliteCount;
    this.status = status;
    this.morphology = morphology;
    this.hdop = hdop;
    this.pdop = pdop;
    this.vdop = vdop;
    this.isStatic = isStatic;
  }

  // UserId,Msisdn,MSISDN_Name,Date,Longitude,Latitude,Elevation,Speed,NumberSatellites,Status,Morphology,HDOP,PDOP,VDOP
  static parse = (raw: string, shouldValidate: boolean) => {
    const values = raw.split(",");

    const userId = values[0];
    const msisdn = values[1];
    const msisdnName = values[2];
    // allowing double/single digit date and time values as well as am/pm
    // assuming that the date was created in utc
    const fileDate = DateTime.fromJSDate(new Date(values[3])).setZone("utc", { keepLocalTime: true }).toString();

    const ascertained = DateTime.fromJSDate(new Date(values[3])).setZone("utc", { keepLocalTime: true }).toMillis();
    
    const longitude = Number(values[4]);
    const latitude = Number(values[5]);
    const elevation = Number(values[6]);
    const speed = values[7];
    const numberSatellites = values[8];
    const status = values[9];
    const morphology = values[10];
    const hdop = values[11];
    const pdop = values[12];
    const vdop = values[13];

    //don't validate the header data
    if (shouldValidate && !isGpsLoggerHeader(raw) && (!msisdn || isNaN(ascertained) || isNaN(longitude) || isNaN(latitude) || isNaN(elevation))) {
      throw new Error(`Invalid gps record. Msisdn ${msisdn}, Date ${ascertained}, Longitude ${longitude}, Latitude ${latitude}, Elevation ${elevation}`);
    }

    const gpsRecord = new GpsLocation(
      userId,
      msisdn,
      msisdnName,
      ascertained,
      fileDate,
      longitude,
      latitude,
      elevation,
      speed,
      numberSatellites,
      status,
      morphology,
      hdop,
      pdop,
      vdop,
      false
    );
    
    return gpsRecord;
  };
}
