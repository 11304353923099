import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ButtonHTMLAttributes } from "react";
import { Button, ButtonProps } from "react-bootstrap";

type Props = ButtonProps & ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: string;
  isSpinning?: boolean;
  disabled?: boolean;
  children: any;
};

const SpinnerButton = ({
  isSpinning,
  disabled,
  children,
  variant = "primary",
  ...props
}: Props) => {
  return (
    <Button
      type="button"
      {...props}
      disabled={disabled || isSpinning}
      variant={variant}
    >
      {isSpinning && <FontAwesomeIcon icon={faSpinner} spin />} {children}
    </Button>
  );
};

export default SpinnerButton;
