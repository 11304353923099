import { eSmlcLocationsReportHeader } from "./ESmlcHelpers";
import Worksheet from "./Worksheet";
import ESmlcLocationExport from "./ESmlcLocationExport";

export default class ESmlcLocationsWorksheet extends Worksheet {
  private _nextRow: number;

  public get hasData() {
    return this._nextRow > 2;
  }

  constructor() {
    super();
    this.writeHeaders();
    this._nextRow = 1;
  }

  public writeData = (l: ESmlcLocationExport): void => {
    const values = [
      [
        l.callId,
        l.msisdn,
        l.locationReturned,
        l.positionMethod,
        l.sourceAltitude,
        l.resultCode,
        l.timeStamp,
        l.shape,
        l.horizontalAccuracy,
        l.verticalAccuracy,
        l.longitude,
        l.latitude,
        l.uncertaintySMajor,
        l.uncertaintySMinor,
        l.uncertainty,
        l.altitude,
        l.uncertaintyAltitude,
        l.confidence,
        l.verticalConfidence,
        l.ha3DLat,
        l.ha3DLong,
        l.ha3DUncertaintySMajor,
        l.ha3DUncertaintySMinor,
        l.ha3DUncertainty,
        l.ha3DConfidence,
        l.ha3DAltitude,
        l.ha3DUncertaintyAltitude,
        l.ha3DVerticalConfidence,
        l.locationSource,
        l.gpsLongitude,
        l.gpsLatitude,
        l.gpsElevation,
        l.gpsAscertainedTime,
      ],
    ];

    this.setCells(this._nextRow, 0, values);

    if (l.timeStamp) {
      this.setCellAsDateFormat(this._nextRow, 6); // col g, timeStamp
    }

    for (let i = 0; i < values[0].length; i++) {
      this.setCellDefaultValueStyles(this._nextRow, i);
    }

    this._nextRow++;
  };

  private writeHeaders = (): void => {
    this.setCells(0, 0, [eSmlcLocationsReportHeader]);
  
    for (let i = 0; i < eSmlcLocationsReportHeader.length; i++) {
      this.setCellDefaultValueStyles(0, i);
    }

    this.setAutofilter("AG");
    this.setESmlcLocationsColumnWidths();
  };
}
