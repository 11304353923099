import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ButtonHTMLAttributes, MouseEvent } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  color: #6c757d;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  
  &:hover:not(:disabled) {
    text-decoration: underline;
    color: #dc3545;
    filter: brightness(85%);
  }

  &:disabled {
    cursor: default;
    color: rgba(80, 80, 80, 0.5);
  }
`;

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: (e: MouseEvent<HTMLButtonElement>, props: any) => void;
  disabled?: boolean;
};

const DeleteButton = ({ onClick, disabled = false, ...props }: Props) => {
  return (
    <span title="Delete" data-toggle="tooltip" data-placement="bottom">
      <StyledButton onClick={(e: MouseEvent<HTMLButtonElement>) => onClick(e, props)} disabled={disabled}>
        <FontAwesomeIcon icon={icon({ name: "trash" })} />
      </StyledButton>
    </span>
  );
};

export default DeleteButton;
