import CorrelationGroup from "./CorrelationGroup";
import EventCache from "./EventCache";

export default class EventsCache {
  public allMsisdns: EventCache = new EventCache();
  public msisdnCaches: Map<string, EventCache> = new Map();

  public cacheCorrelationGroup = (
    correlationGroup: CorrelationGroup,
    yieldExclude: boolean,
    horizontalAccuracyThreshold: number,
    verticalAccuracyThreshold: number
  ): void => {
    if (!correlationGroup.mlpLocation.correlatedEvent) {
      return;
    }

    this.allMsisdns.cacheCorrelationGroup(
      correlationGroup,
      yieldExclude,
      horizontalAccuracyThreshold,
      verticalAccuracyThreshold
    );

    if (!this.msisdnCaches.has(correlationGroup.mlpLocation.correlatedEvent.msisdn!)) {
      this.msisdnCaches.set(correlationGroup.mlpLocation.correlatedEvent.msisdn!, new EventCache());
    }

    this.msisdnCaches
      .get(correlationGroup.mlpLocation.correlatedEvent.msisdn!)
      ?.cacheCorrelationGroup(
        correlationGroup,
        yieldExclude,
        horizontalAccuracyThreshold,
        verticalAccuracyThreshold
      );
  };
}
