import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { apiToAppPermissions } from "../components/auth/roles";
import { getPermissions } from "../services/apiService";
import { getValidUSTimeZoneIdentifier, TimeZoneIdentifier } from "../utilities/timezone";
import { validTimeZones } from "../utilities/constants";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";

type UserHasAppPermissionCallback = (appPermission: string) => boolean;

type UserContextValue = {
  permissions: UserPermissions;
  isLoading: boolean;
  userTimeZoneIdentifier: TimeZoneIdentifier;
  currentUser?: AccountInfo;
  userHasAppPermission: UserHasAppPermissionCallback;
};

type UserPermissions = {
  apiPermissions: string[];
  browserPermissions: string[];
};

const defaultValue: UserContextValue = {
  permissions: {
    apiPermissions: [],
    browserPermissions: [],
  },

  // Set the initial loading state to true to prevent pages from rendering
  // before permissions have been fetched.
  isLoading: true,
  userTimeZoneIdentifier: validTimeZones.pacific.identifier,
  userHasAppPermission: (_) => false,
};

export const UserContext = createContext<UserContextValue>(defaultValue);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [permissions, setPermissions] = useState<UserPermissions>(defaultValue.permissions);
  const [isLoading, setIsLoading] = useState(defaultValue.isLoading);
  const { accounts } = useMsal();
  const userTimeZoneIdentifier = getValidUSTimeZoneIdentifier(Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getPermissions();
        const apiPermissions = response.data?.permissions || [];
        const appPermissions = apiToAppPermissions(apiPermissions);
        setPermissions({ apiPermissions, browserPermissions: appPermissions });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const userHasAppPermission: UserHasAppPermissionCallback = (appPermission) =>
    permissions.browserPermissions.includes(appPermission);

  return (
    <UserContext.Provider
      value={{ permissions, isLoading, userTimeZoneIdentifier, currentUser: accounts[0], userHasAppPermission }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within a <UserContextProvider />");
  }

  return context;
};
