export class ApiResponse<TData> {
  data: TData | null;
  response: Response | null;
  error: string[] | null;
  hasError: boolean;

  constructor(
    response: Response | null,
    data: TData | null = null,
    error: string[] | null
  ) {
    this.response = response;
    this.data = data;
    this.error = error;
    this.hasError = this.error !== null;
  }
}
